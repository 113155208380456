import axios from "axios";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Api, { API_HOST } from "services/Api";
import { CompanyContext } from "./CompanyContext";
import { MediaContext } from "./MediaContext";
import { resizeImageFile } from "utils/resizeImageFile";
import getFileSize from "utils/getFileSize";
import mime from "mime";

const UploadContext = React.createContext({
    isOpenedUploadModal: false,
    filesUploadModal: [],
    addFilesUploadModal: ([]) => void 0,
    addFilesAsURLUploadModal: (url, filename, asset) => void 0,
    closeUploadModal: () => void 0,
    openUploadModal: () => void 0,
    totalFileQuantityUploadModal: 0,
    actualFileProgressUploadModal: 0,
    urlToFileUploadModal: async (url) => void 0,
    presendFileToAPIUploadModal: (file, designId = undefined) => void 0,
    sendFileToAPIUploadModal: (file, name, width = 0, height = 0, designId = undefined) => void 0,
    sendFileToAnyAPIEndpoint: (endpoint, file) => void 0,
    blobToFile: (blob, name) => void 0,
    urlToBlobObjectURL: (url) => void 0,
    urlToBase64: (url) => void 0
});

const UploadProvider = ({ children }) => {
    
    const {company} = useContext(CompanyContext);
    const [isOpened, setIsOpened] = useState(false);
    const [files, setFiles] = useState([]);
    const [totalFileQuantity, setTotalFileQuantity] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [actualFileProgress, setActualFileProgress] = useState(0);
    const { updateMyMedia } = useContext(MediaContext);

    function makeXhrRequest(opts) {
        return new Promise(function (resolve, reject) {
            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.open(opts.method, opts.url);
            xhr.onload = function () {
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve(xhr.response);
                } else {
                    reject({
                        status: xhr.status,
                        statusText: xhr.statusText
                    });
                }
            };
            xhr.onerror = function () {
                reject({
                    status: xhr.status,
                    statusText: xhr.statusText
                });
            };
            if (opts.headers) {
                Object.keys(opts.headers).forEach(function (key) {
                    xhr.setRequestHeader(key, opts.headers[key]);
                });
            }
            var params = opts.params;
            if (params && typeof params === 'object') {
                params = Object.keys(params).map(function (key) {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
                }).join('&');
            }
            xhr.send(params);
        });
      }

    const urlToFile = async (url) => {
        return await fetch(url, { 
            cache: "no-cache" 
        }).then(async response => {
            const contentType = response.headers.get('content-type');
            const cleanUrl = url.split("?")[0];
            const extension = !!contentType && contentType?.split("/")?.[1] ? contentType?.split("/")?.[1] : cleanUrl.split(".")?.pop();
            const blob = await response.blob();
            const file = new File(
                [blob], 
                `file_${company?.id}_${new Date().getTime()}.${extension}`, 
                { type: mime.getType(extension) }
            );
            return file;
        });
    }

    const dataURLtoFile = (dataurl, name = undefined) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        const filename = name || `file_${company?.id}_${new Date().getTime()}.${mime.split("/")[1]}`;
        return {
            file: new File([u8arr], filename, {type:mime}),
            name: filename
        };
    }

    const urlToBlobObjectURL = async (url) => {
        return await fetch(url).then(res => res.blob()).then(blob => URL.createObjectURL(blob));
    }
    
    const urlToBase64 = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
          };
          reader.onerror = reject;
        });
    }

    const blobToFile = (blob, name) => {
        return new File([blob], name);
    }

    const closeModal = () => {
        setIsOpened(false);
    }

    const openModal = () => {
        setIsOpened(true);
    }

    const addFilesAsURL = (url, designId = undefined, asset = undefined) => {
        urlToFile(url).then((file) => {
            addFiles([{ file: file, designId: designId, asset: asset }]);
        })
    }

    const addFiles = (_files) => {
        const newFileArray = [...files, ..._files]
        setFiles(newFileArray);
        setTotalFileQuantity(newFileArray?.length);
        setIsOpened(true);
    }

    const sendFileToAnyAPIEndpoint = async (endpoint, file) => {
        try {
            const { aws_uri, aws_final_url } = await Api.post(endpoint, {}, { filename: file.name, visibility: "public" }).then((response) => {
                const aws_uri = response.data.presigned_url;
                const aws_final_url = response.data.url;
                return { aws_uri, aws_final_url };
            });
            await axios.put(aws_uri, file, {
                headers: { 'Content-type': file.type },
            }).catch(() => {
                return null;
            });
            return aws_final_url;
        } catch {
            return null;
        }

        return null;
    }

    const sendFileToAPI = async (file, name = "", width = 0, height = 0, designId = undefined, _asset = undefined) => {
        
        if (file.type.includes("image") && file?.size > 2 * 1024 * 1000) {
            const response = dataURLtoFile(await resizeImageFile(file));
            file = response.file;
            name = response.name;
        }
        
        if (!file.type.includes("image") && !file.type.includes("video")) {
            setFiles(prevFiles => prevFiles.slice(1));
            setIsUploading(false);
            return;
        }

        if (!name) {
            name = file.name;
        }

        if (designId == "undefined") {
            designId = undefined;
        }

        const type = file.type.includes("image") ? "image" : "video";

        try {
            if (_asset?.id && designId) {
                await Api.put(`/assets/${_asset?.id}`, {}, { vista_project_id: !!designId && designId != "undefined" ? `${designId}` : undefined }).then(() => {});
                _asset = { ..._asset, vista_project_id: !!designId && designId != "undefined" ? `${designId}` : undefined };
            }
            const asset = _asset?.id 
                ? _asset 
                : await Api.post(`/companies/${company?.id}/assets`, {}, { name: name, type: type, vista_project_id: !!designId && designId != "undefined" ? `${designId}` : undefined }).then((response) => {
                    const asset = response.data;
                    return asset;
                });
            const { aws_uri, aws_final_url } = await Api.post(`/assets/${asset.id}/${file.type.split("/")[0]}`, {}, { filename: name, width: width, height: height, type: type, visibility: "public" }).then((response) => {
                const aws_uri = response.data.presigned_url;
                const aws_final_url = response.data.url;
                return { aws_uri, aws_final_url };
            });
            await axios.put(aws_uri, file, {
                headers: { 'Content-type': file.type },
                onUploadProgress: (progressEvent) => {
                    let percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total,
                    );
                    setActualFileProgress(percentCompleted);
                }
            }).then(() => {
                setActualFileProgress(0);
                setFiles(prevFiles => prevFiles.slice(1));
                setIsUploading(false);
                updateMyMedia();
            }).catch(() => {
                setActualFileProgress(0);
                setErrors([...errors, name]);
                setFiles(prevFiles => prevFiles.slice(1));
                setIsUploading(false);
            });

            return { id: asset?.id, url: aws_final_url };
        } catch {
            setActualFileProgress(0);
            setErrors([...errors, name]);
            setFiles(prevFiles => prevFiles.slice(1));
            setIsUploading(false);
        }

        return null;
    }

    const presendFileToAPI = async (f, designId = undefined, asset = undefined) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function() { 
                if (f.type.includes("image")) {
                    var img = new Image;
                    img.onload = function() {
                        sendFileToAPI(f, f.name, img.width, img.height, designId, asset);
                    };
                    img.src = reader.result;
                } 
                else if (f.type.includes("video")) {
                    sendFileToAPI(f, f.name, undefined, undefined, designId, asset);
                } 
                else {
                    sendFileToAPI(f, f.name, undefined, undefined, designId, asset);
                }
            };
            reader.readAsDataURL(f);
        });
    }

    useEffect(() => {
        let _files = files.filter(f => !f?.asset?.id);
        if (!_files || _files?.length == 0) {
            setTimeout(() => {
                setIsOpened(false);
                setTotalFileQuantity(0);
            }, 2000)
        } else {
            setIsOpened(true);
        }
    }, [files?.length]);

    useEffect(() => {
        // const interval = setInterval(() => {
            if (!isUploading) {
                if (files && files?.length > 0) {
                    setIsUploading(true);
                    const f = files[0];
                    presendFileToAPI(f.file || f, f?.designId, f?.asset).then(() => {});
                }
            }
        // }, 500);
        // return () => clearInterval(interval);
    }, [isUploading, files?.length]);


    return (
        <UploadContext.Provider value={{ 
            isOpenedUploadModal: isOpened, 
            closeUploadModal: closeModal, 
            openUploadModal: openModal, 
            addFilesUploadModal: addFiles, 
            addFilesAsURLUploadModal: addFilesAsURL,
            urlToFileUploadModal: urlToFile,
            presendFileToAPIUploadModal: presendFileToAPI,
            sendFileToAPIUploadModal: sendFileToAPI,
            sendFileToAnyAPIEndpoint: sendFileToAnyAPIEndpoint,
            blobToFile: blobToFile,
            urlToBlobObjectURL: urlToBlobObjectURL,
            urlToBase64: urlToBase64,
            filesUploadModal: files,
            totalFileQuantityUploadModal: totalFileQuantity,
            actualFileProgressUploadModal: actualFileProgress
        }}>
            {children}
        </UploadContext.Provider>
    );
};

export { UploadContext, UploadProvider };