import OutOfWordsModal from "components/layout/OutOfWordsModal";
import SelectPlanModal from "components/layout/SelectPlanModal";
import { CompanyContext } from "contexts/CompanyContext";
import { PriorityModalContext } from "contexts/PriorityModalContext";
import { SessionContext } from "contexts/SessionContext";
import { get, replace } from "lodash";
import { useContext, useEffect, useState } from "react";
import Api from "services/Api";
import { replaceAll } from "utils/replaceAll";
import { formatSocialCommentObj, formatSocialConversationObj, formatSocialMessageObj, formatSocialPostObj } from "../../formatSocialPostObj";
import { Notification, toast } from "components/ui";
import capitalize from "components/ui/utils/capitalize";
import { getSocialNetworkIntegration } from "utils/getSocialNetworkIntegration";

export const useInstagram = () => {

    const { company, companySocials } = useContext(CompanyContext);
    const _start_date = new Date(new Date().getTime() - 30 * 24 * 3600 * 1000);
    const _end_date = new Date();

    const network = "instagram";

    const getInstagramPostsInsights = async (
        per_page = 100, // 100
        start_date = _start_date,
        end_date = _end_date
    ) => {
        const socialNetwork = getSocialNetworkIntegration("instagram", companySocials);
        if (!socialNetwork) return [];

        return await getInstagramPosts(per_page, start_date, end_date, false);
    }
    
    const getInstagramPosts = async (
        per_page = 100, // 100
        start_date = _start_date,
        end_date = _end_date,
        get_comments = true 
    ) => {
        const socialNetwork = getSocialNetworkIntegration("instagram", companySocials);
        if (!socialNetwork) return [];

        // start_date = new Date(start_date).toISOString();
        // end_date = new Date(end_date).toISOString();
        start_date = new Date("2020-01-01").toISOString();
        end_date = new Date().toISOString();
        
        let posts = [];

        if (localStorage.getItem(`${socialNetwork.id}_posts_${start_date.split("T")[0]}_${end_date.split("T")[0]}`)) {
            const timestamp = localStorage.getItem(`${socialNetwork.id}_posts_timestamp_${start_date.split("T")[0]}_${end_date.split("T")[0]}`);
            if (parseInt(new Date().getTime()) <= parseInt(timestamp) + 1000 * 60 * 2) {
                posts = JSON.parse(localStorage.getItem(`${socialNetwork.id}_posts_${start_date.split("T")[0]}_${end_date.split("T")[0]}`));
            }
        }

        if (!posts?.length) {
            // posts = await Api.get(`/socialNetworks/${socialNetwork.id}/posts?${per_page ? `&limit=${per_page}` : ``}&fields=media_url,like_count,comments_count,media_type,id,caption,ig_id,permalink,thumbnail_url,timestamp,username,media_product_type,comments{id,from,text,timestamp,user,username,replies{id,from,text,timestamp,user,username}},insights.metric(impressions,comments,likes,shares){values,name}`).then(({ data: posts }) => { 
            posts = await Api.get(`/socialNetworks/${socialNetwork.id}/posts?${start_date ? `&since=${Math.ceil(new Date(start_date).getTime() / 1000)}` : ``}${end_date ? `&until=${Math.ceil(new Date(end_date).getTime() / 1000)}` : ``}${per_page ? `&limit=${per_page}` : ``}&fields=media_url,like_count,comments_count,media_type,id,caption,ig_id,permalink,thumbnail_url,timestamp,username,media_product_type,insights.metric(impressions,comments,likes,shares){values,name}`).then(({ data: posts }) => { 
                posts = posts?.map((post) => {
                    return formatSocialPostObj(
                        "instagram",
                        post?.id,
                        post?.ig_id,
                        socialNetwork?.name,
                        post?.username,
                        socialNetwork?.image?.url,
                        post?.timestamp,
                        post?.caption,
                        [{ type: post?.media_type?.toLowerCase(), url: post?.media_url, width: 1000, height: 1000 }],
                        post?.insights?.data?.filter(e => e?.name == "likes")?.[0]?.values?.[0]?.value,
                        post?.insights?.data?.filter(e => e?.name == "comments")?.[0]?.values?.[0]?.value,
                        post?.insights?.data?.filter(e => e?.name == "impressions")?.[0]?.values?.[0]?.value,
                        post?.insights?.data?.filter(e => e?.name == "shares")?.[0]?.values?.[0]?.value,
                        "",
                        "",
                        "",
                        "FEED",
                        undefined,
                        undefined,
                        [{ type: post?.media_type?.toLowerCase(), url: post?.media_url, width: 1000, height: 1000 }],
                        { media_product_type: "FEED" }
                    )
                });
                localStorage.setItem(`${socialNetwork.id}_posts_timestamp_${start_date.split("T")[0]}_${end_date.split("T")[0]}`, new Date().getTime());
                return posts; 
            });
        }
        
        try {
            let comments = await Api.get(`/comments?countUnreadByPost=true`).then(({ data: comments }) => comments);
            posts = posts.map((post) => ({
                ...post,
                unreadComments_count: comments.find(e => e?.external_post_id == post?.id)?.comments_count
            }))
        } catch (err) {

        }
        
        if (posts) {
            localStorage.setItem(`${socialNetwork.id}_posts_${start_date.split("T")[0]}_${end_date.split("T")[0]}`, JSON.stringify(posts));
        }

        return posts;
    }
    
    const replyToInstagramComment = async (
        comment,
        message,
    ) => {
        const socialNetwork = getSocialNetworkIntegration(network, companySocials);
        if (!socialNetwork) return [];

        await Api.post(`/comments`, {}, {
            external_comment_id: comment?.external_id,
            message: message
        });
    }
    
    const deleteInstagramMessage = async (message_id) => {
        
        const socialNetwork = getSocialNetworkIntegration(network, companySocials);
        if (!socialNetwork) return [];

        try {
            const response = await Api.delete(`/messages/${message_id}`);
        } catch (e) {
            let message = e?.message;
            if (message) {
                message = message.split(`"error":{"message":"`);
                if (message?.length > 1) {
                    message = message[1];
                    message = message.split(`","type"`)?.[0];
                } else {
                    message = null;
                }
            }
            toast.push(<Notification type="danger" title={message || e?.message || `An error ocurred while deleteing the message`} />);
            return false;
        }
        
        return true;
    }

    // const replyToInstagramComment = async (
    //     comment,
    //     message,
    // ) => {

    //     const socialNetwork = getSocialNetworkIntegration("instagram", companySocials);
    //     if (!socialNetwork) return [];

    //     let start_date = new Date(_start_date).toISOString();
    //     let end_date = new Date(_end_date).toISOString();

    //     let _theComment;

    //     try {
    //         const response = await Api.post(`/socialNetworks/${socialNetwork.id}/proxy`, {}, {
    //             url: `https://graph.facebook.com/v16.0/${comment?.id}/replies`,
    //             method: "post",
    //             params: {
    //                 message: message
    //             }
    //         }).then((response) => response);
    //         _theComment = formatSocialCommentObj(
    //             "instagram",
    //             response?.id,
    //             new Date().toISOString(),
    //             socialNetwork?.instagram_id,
    //             socialNetwork?.name,
    //             socialNetwork?.username,
    //             socialNetwork?.image?.url,
    //             message,
    //             [],
    //             [],
    //         )
    //         if (localStorage.getItem(`${socialNetwork.id}_posts_${start_date.split("T")[0]}_${end_date.split("T")[0]}`)) {
    //             let posts = JSON.parse(localStorage.getItem(`${socialNetwork.id}_posts_${start_date.split("T")[0]}_${end_date.split("T")[0]}`));
    //             posts = posts?.map((post) => {
    //                 if (post?.comments && post?.comments?.length) {
    //                     post.comments = post?.comments?.map((_comment) => {
    //                         if (_comment?.id == comment?.id) {
    //                             if (_comment?.replies?.length == 0) {
    //                                 _comment.replies = [_theComment];
    //                             } else {
    //                                 _comment?.replies?.unshift(_theComment);
    //                             }
    //                         }
    //                         return _comment;
    //                     })
    //                 }
    //                 return post;
    //             });
    //             localStorage.setItem(`${socialNetwork.id}_posts_${start_date.split("T")[0]}_${end_date.split("T")[0]}`, JSON.stringify(posts));
    //         }
    //     } catch (err) {
    //         toast.push(<Notification type="danger" title={`An error ocurred while sending the ${capitalize(socialNetwork.type)} comment`} />);
    //     }

    //     return _theComment;
    // }

    const getInstagramComments = async (
        id
    ) => {

        const socialNetwork = getSocialNetworkIntegration(network, companySocials);
        if (!socialNetwork) return [];

        const comments = await Api.get(`/comments?sort=-id&filter[parent_id]=null&filter[external_post_id]=${id}&include=externalUser.image,children.externalUser.image`).then(({ data }) => data);

        return comments;
    }

    // const sendInstagramMessage = async (conversation, message) => {

    //     const socialNetwork = getSocialNetworkIntegration("instagram", companySocials);
    //     if (!socialNetwork) return [];

    //     const user_id = conversation?.user_id;
    //     const conversation_id = conversation?.id;

    //     let _theMessage;
    //     try {
    //         const response = await Api.post(`/socialNetworks/${socialNetwork.id}/proxy`, {}, {
    //             url: `https://graph.facebook.com/v16.0/${socialNetwork?.page_id}/messages`,
    //             method: "post",
    //             params: {
    //                 recipient: {'id': user_id},
    //                 messaging_type: "RESPONSE",
    //                 message: {'text': message}
    //             }
    //         }).then((response) => response);

    //         _theMessage = formatSocialMessageObj(
    //             "instagram",
    //             response?.message_id,
    //             new Date().getTime(),
    //             socialNetwork?.page_id,
    //             message
    //         );

    //         if (localStorage.getItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages`)) {
    //             let messages = JSON.parse(localStorage.getItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages`));
    //             messages.unshift(_theMessage);
    //             localStorage.setItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages`, JSON.stringify(messages));
    //         }
    //     } catch (err) {
    //         toast.push(<Notification type="danger" title={`An error ocurred while sending ${capitalize(socialNetwork.type)} message`} />);
    //     }

    //     // if (_theMessage) {
    //     //     if (localStorage.getItem(`${company.id}_${socialNetwork.id}_conversations`)) {
    //     //         let conversations = JSON.parse(localStorage.getItem(`${company.id}_${socialNetwork.id}_conversations`));
    //     //         conversations[conversations?.findIndex(e => e.id == conversation_id)]?.messages?.unshift(_theMessage);
    //     //         conversations[conversations?.findIndex(e => e.id == conversation_id)].message = _theMessage?.message;
    //     //         localStorage.setItem(`${company.id}_${socialNetwork.id}_conversations`, JSON.stringify(conversations));
    //     //     }
    //     // }

    //     return _theMessage;
    // }

    const sendInstagramMessage = async (conversation, message) => {

        const socialNetwork = getSocialNetworkIntegration(network, companySocials);
        if (!socialNetwork) return [];

        const _message = await Api.post(`/messages`, {}, {
            conversation_id: conversation.id,
            message: message
        }).then(({ data: message }) => {
            return message;
        }).catch((err) => {
            toast.push(<Notification type="danger" title={err?.message || err} />);
        })

        return _message;
    }
    
    const getInstagramConversationMessages = async (
        conversation_id,
        per_page = 100, 
    ) => {

        const socialNetwork = getSocialNetworkIntegration(network, companySocials);
        if (!socialNetwork) return [];

        const messages = await Api.get(`/messages?filter[conversation_id]=${conversation_id}&sort=-id&include=binaries`).then(
            ({ data: messages }) => messages);
        
        return messages;
    }

    // const getInstagramConversationMessages = async (
    //     conversation_id,
    //     per_page = 100, //100
    // ) => {
    //     const socialNetwork = getSocialNetworkIntegration("instagram", companySocials);
    //     if (!socialNetwork) return [];
        
    //     if (localStorage.getItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages`) && localStorage.getItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages_timestamp`)) {
    //         const timestamp = localStorage.getItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages_timestamp`);
    //         if (parseInt(new Date().getTime()) <= parseInt(timestamp) + 1000 * 29) {
    //             return JSON.parse(localStorage.getItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages`));
    //         }
    //     }

    //     let messages = [];
    //     try {
    //         const response = await Api.post(`/socialNetworks/${socialNetwork.id}/proxy`, {}, {
    //             url: `https://graph.facebook.com/v16.0/${conversation_id}`,
    //             method: "get",
    //             params: {
    //                 fields: "messages{id,created_time,from,message,attachments}"
    //             }
    //         }).then((response) => response);
    //         messages = response?.messages?.data;
    //     } catch (err) {
    //         // toast.push(<Notification type="danger" title={`An error ocurred while fetching ${capitalize(socialNetwork.type)} conversations`} />)
    //     }
        
    //     if (messages) {
    //         messages = messages.map((item) => {
    //             return formatSocialMessageObj(
    //                 "instagram",
    //                 item?.id,
    //                 item?.created_time,
    //                 item?.from?.id,
    //                 item?.message,
    //                 item?.attachments?.data?.map(e => e?.image_data?.url ? e?.image_data?.url : e?.video_data?.url)
    //             );
    //         }); 
    //         if (messages && messages?.length) {
    //             Object.entries(localStorage).map(x => x[0]).filter(x => x.includes(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages`)).map(x => localStorage.removeItem(x));
    //             localStorage.setItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages`, JSON.stringify(messages));
    //             localStorage.setItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages_timestamp`, new Date().getTime());
    //         }
    //     } else {    
    //         if (localStorage.getItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages`)) {
    //             messages = JSON.parse(localStorage.getItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages`));
    //         }
    //     }

    //     return messages && messages?.length ? messages : [];
    // }

    const getInstagramConversations = async (filters) => {
        
        const socialNetwork = getSocialNetworkIntegration(network, companySocials);
        if (!socialNetwork) return [];

        let filterArray = [];
        filterArray.push(`filter[social_network_id]=${socialNetwork.id}`);
        if (!!filters?.query) {
            filterArray.push(`filter[externalUser.name]=${filters?.query}`);
        }
        if (!!filters?.assignedTo) {
            filterArray.push(`filter[user_id]=${filters?.assignedTo}`);
        }
        if (!!filters?.unread) {
            filterArray.push(`filter[has_unreadMessages]=${1}`);
        }

        try {
            const conversations = await Api.get(`/conversations?${filterArray.join("&")}&sort=lastMessage&include=lastMessage,image,unreadMessagesCount,externalUser.image`).then(({ data: conversations }) => {
                conversations = conversations.map(c => ({...c, network: network}));
                return conversations;
            });
            return conversations;
        } catch (err) {}

        return [];
    }
    
    // const getInstagramConversations = async (
    //     per_page = 20, // 20
    // ) => {
    //     const socialNetwork = getSocialNetworkIntegration("instagram", companySocials);
    //     if (!socialNetwork) return [];

    //     let conversations = [];
        
        // if (localStorage.getItem(`${company.id}_${socialNetwork.id}_conversations`) && localStorage.getItem(`${company.id}_${socialNetwork.id}_conversations_timestamp`)) {
        //     const timestamp = localStorage.getItem(`${company.id}_${socialNetwork.id}_conversations_timestamp`);
        //     if (parseInt(new Date().getTime()) <= parseInt(timestamp) + 1000 * 30) {
        //         conversations = JSON.parse(localStorage.getItem(`${company.id}_${socialNetwork.id}_conversations`));
        //     }
        // }

        // if (!conversations?.length) {
        //     try {
        //         const response = await Api.post(`/socialNetworks/${socialNetwork.id}/proxy`, {}, {
        //             url: `https://graph.facebook.com/v16.0/${socialNetwork?.page_id}/conversations`,
        //             method: "get",
        //             params: {
        //                 limit: per_page,
        //                 fields: "participants,updated_time,id,messages.limit(1){message,from}",
        //                 platform: "instagram"
        //             }
        //         }).then((response) => response);
        //         conversations = response?.data;
        //         localStorage.setItem(`${company.id}_${socialNetwork.id}_conversations_timestamp`, new Date().getTime());
        //     } catch (err) {
        //         // toast.push(<Notification type="danger" title={`An error ocurred while fetching ${capitalize(socialNetwork.type)} conversations`} />)
        //     }
            
        //     if (conversations) {
        //         conversations = conversations.map((item) => {
        //             const user = item?.participants?.data?.pop();
        //             return formatSocialConversationObj(
        //                 "instagram",
        //                 item?.id,
        //                 item?.updated_time,
        //                 user?.id,
        //                 user?.name ? user?.name : user?.username,
        //                 user?.email,
        //                 undefined, // `https://graph.facebook.com/${user?.id}/picture?access_token=PAGE_ACCESS_TOKEN`,
        //                 undefined,
        //                 undefined,
        //                 undefined,
        //                 undefined,
        //                 undefined,
        //                 undefined,
        //                 undefined,
        //                 item?.messages?.data?.[0]?.message,
        //                 item?.messages?.data?.[0]?.from?.id == socialNetwork?.page_id,
        //             );
        //         }); 
        //     } else {    
        //         if (localStorage.getItem(`${company.id}_${socialNetwork.id}_conversations`)) {
        //             conversations = JSON.parse(localStorage.getItem(`${company.id}_${socialNetwork.id}_conversations`));
        //         }
        //     }
        // }
        
        // try {
        //     const db_conversations = await Api.get(`/conversations?sort=-id&filter[social_network_id]=${socialNetwork?.id}&filter[type]=conversation`).then(({ data: conversations }) => conversations);
        //     if (db_conversations && db_conversations?.length) {
        //         conversations = conversations?.map((item) => {
        //             const _db_conversation = db_conversations?.filter(e => e.external_id == item?.id)?.[0];
        //             let _item = item;
        //             if (_db_conversation) {
        //                 _item = {
        //                     ...item,
        //                     db_id: _db_conversation?.id,
        //                     assigned_user_id: _db_conversation?.user_id,
        //                     last_read_at: _db_conversation?.last_read_at
        //                 }
        //             }
        //             return _item;
        //         });
        //     }
        // } catch (err) {}

        // conversations = conversations?.map((item) => {
        //     return {
        //         ...item,
        //         unreadMessages_count: (!item?.last_read_at || (new Date(item.last_read_at).getTime() < new Date(item.created_at))) && !item.is_last_message_from_user ? 1 : 0
        //     }
        // });
        
        // if (conversations && conversations?.length) {
        //     localStorage.setItem(`${company.id}_${socialNetwork.id}_conversations`, JSON.stringify(conversations));
        // }

        // return conversations && conversations?.length ? conversations : [];
    // }

    return { 
        getInstagramPosts, 
        getInstagramConversations, 
        sendInstagramMessage, 
        getInstagramConversationMessages, 
        getInstagramComments, 
        replyToInstagramComment, 
        getInstagramPostsInsights,
        deleteInstagramMessage 
    }
}

