import { calculateTwitterChars } from "utils/calculateTwitterChars";
import { HiOutlineX } from 'react-icons/hi';
import { HiOutlineCheck } from 'react-icons/hi2';
import { BsThreeDots } from 'react-icons/bs';
import isMac from "utils/isMac";
import { stripHTML } from "utils/stripHTML";
import { formatNumber } from "utils/formatNumber";
import { Button } from "components/ui";
import usePostValidator from "utils/hooks/usePostValidator";
import MagicTextOptionsButton from "./MagicTextOptionsButton";

export const getNetworkCharCount = (network, string) => {
    return network == "twitter" 
        ? formatNumber(calculateTwitterChars(string || "")) 
        : formatNumber(stripHTML(string || "")?.length)
}

export const getNetworkCharCountUnformatted = (network, string) => {
    return network == "twitter" 
        ? calculateTwitterChars(string || "") 
        : stripHTML(string || "")?.length
}

const OptionsBar = ({
    value,
    setValue,
    network,
    isHTML = false,
    isTitle = false,
    post = undefined,
    onCancel = undefined,
    onConfirm = undefined
}) => {

    const { validationConstants } = usePostValidator();
    const networkValidationIndex = network == "instagram" ? "instagram_feed" : network;

    const maxLength = 
        isTitle 
            ? validationConstants?.[networkValidationIndex]?.title?.max_length || 0
            : validationConstants?.[networkValidationIndex]?.description?.max_length || 0

    return (
        <div className={`w-full px-0 ${network == "medium" ? `px-2 mb-4 pb-4` : `mb-2 pb-2 mt-2`} flex justify-between border-b border-gray-100 dark:border-gray-700 bg-white dark:bg-gray-800`}>
            {!(isHTML || (network == "medium" && isTitle == false)) && <div className="hidden lg:flex text-gray-600 dark:text-gray-400 items-center gap-1 left-5 bottom-3 text-xs">
                <span className='button-key'>{isMac() ? <>⌘</> : <>Ctrl</>} + E</span> AI Tools
            </div>}
            <div className="flex items-center gap-4 right-5 bottom-3 text-xs">
                <span className='text-xs text-gray-600 dark:text-gray-400'>
                    Chars: {getNetworkCharCount(networkValidationIndex, value)}&nbsp; 
                    <span className={`${getNetworkCharCount(networkValidationIndex, value) > maxLength && `font-bold text-red-600`}`}>{maxLength && `/ ${formatNumber(maxLength)}`} {getNetworkCharCount(networkValidationIndex, value) > maxLength && `(${maxLength - getNetworkCharCount(networkValidationIndex, value)})`}</span> 
                </span>
            </div>
            <div className='flex items-center gap-1.5'>
                <MagicTextOptionsButton 
                    isTitle={isTitle}
                    post={post}
                    setValue={setValue}
                    network={network}
                />
                {onCancel && <Button
                    variant="twoTone"
                    color="gray-600"
                    icon={<HiOutlineX />}
                    size="xs"
                    className="!rounded-lg"
                    onClick={onCancel}
                />}
                {onConfirm && <Button
                    variant="filled"
                    color="green-600"
                    icon={<HiOutlineCheck />}
                    size="xs"
                    className="!rounded-lg"
                    onClick={onConfirm}
                />}
            </div>
        </div>
    );
}

export default OptionsBar;