import { Container } from "components/shared";
import { Button, Skeleton, Spinner } from "components/ui";
import { CompanyContext } from "contexts/CompanyContext";
import { useContext, useEffect, useRef, useState } from "react";
import { Trans } from "react-i18next";
import { BsStars } from "react-icons/bs";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Api from "services/Api";
import MediaElement from "views/calendar/MediaElement";
import AIImageDropdownSizes from "./components/AIImageDropdownSizes";
import InputModern from "components/ui/Input/InputModern";
import useRandomContexts from "utils/hooks/useRandomContexts";
import { getAspectRatio } from "utils/getAspectRatio";
import AIImageDropdownStyles from "./components/AIImageDropdownStyles";
import { aiImageStyles } from "views/company_settings/components/AIImages";
import AIImageDropdownModels from "./components/AIImageDropdownModels";
import { AIContext } from "contexts/AIContext";
import AIImageGenerateButton from "./components/AIImageGenerateButton";
import autosize from "autosize";

const AIImageBody = ({
    inputClassName = "",
    columnBreakPoints = { 350: 2, 1024: 3 },
    showPreview = true,
    handleOnSelect = undefined,
    handleOnUnselect = undefined,
    selectedImages = undefined, 
    isSelectable = undefined
}) => {
    
    const { company } = useContext(CompanyContext);
    const { getImages, getDriverModel, getByQueryChat, aiModels } = useContext(AIContext);
    const { generateRandomAIImagePrompts } = useRandomContexts();
    const [ query, setQuery ] = useState("");
    const [ model, setModel ] = useState(getDriverModel("image")?.model);
    const [ n, setN ] = useState(1);
    const [ size, setSize ] = useState("1024x1024");
    const [ style, setStyle ] = useState(aiImageStyles[0].style);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoadingSuggestions, setIsLoadingSuggestions ] = useState(false);
    const [ isLoadingHistory, setIsLoadingHistory ] = useState(true);
    const [ fixedQuery, setFixedQuery ] = useState();
    const [ historyImages, setHistoryImages ] = useState();
    const [ suggestions, setSuggestions ] = useState([]);
    const [ nextLink, setNextLink ] = useState();
    const inputRef = useRef();

    const getHistoryImages = (replace = true) => {
        setIsLoadingHistory(true);
        Api.get(!replace && nextLink ? nextLink : `/aiResults?include=images,images.thumbnail,user,user.image&sort=-created_at&filter[company_id]=${company?.id}&filter[type]=image`).then(({ data: results, links }) => {
            setHistoryImages(replace ? results : [...historyImages, ...results]);
            setNextLink(links?.next);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            setIsLoadingHistory(false);
        });
    }

    const _removeSavedSuggestion = (_query) => {
        let savedSuggestions = suggestions;
        savedSuggestions = savedSuggestions.filter(suggestion => suggestion?.toLowerCase() != _query?.toLowerCase());
        setSuggestions(savedSuggestions);
        localStorage.setItem(`aiImage.suggestions.${company?.id}`, JSON.stringify(savedSuggestions));
    }

    const generateImages = async (n = 1) => {
        let _query = query;
        _removeSavedSuggestion(_query)
        setFixedQuery("");
        setN(n);
        setIsLoading(true);
        let _style = style;
        if (!_style) _style = aiImageStyles.map(e => e.style).random();
        const modelObj = aiModels.filter(e => e.type == "image").find(e => e.model == model);
        if (modelObj?.needsTranslation) {
            _query = await getByQueryChat(`Translate this text to English or keep it the same if it's already in English: ${_query}
                Translated text: `, false, true, "");
        }
        _query = `${_style != "No style" ? `${_style} image: ` : ``}${_query}`;
        await Promise.allSettled([...Array(n).keys()].map(e => getImages(_query, size, model)));
        getHistoryImages();
        setIsLoading(false);
    }

    const initialSuggestions = async (regenerate = false) => {
        setIsLoadingSuggestions(true);
        setSuggestions(
            !!localStorage.getItem(`aiImage.suggestions.${company?.id}`) && !regenerate 
                ? JSON.parse(localStorage.getItem(`aiImage.suggestions.${company?.id}`))
                : await generateRandomAIImagePrompts(undefined, !!suggestions?.length)
        );
        setIsLoadingSuggestions(false);
    }

    const resize = () => {
      inputRef.current.style.cssText = 'height: 100px !important';
      inputRef.current.style.cssText = `height: ${Math.max(inputRef.current.scrollHeight, 0)}px !important`;
    }

    useEffect(() => {
        if (!!suggestions?.length) {
            localStorage.setItem(`aiImage.suggestions.${company?.id}`, JSON.stringify(suggestions));
        }
    }, [suggestions]);

    useEffect(() => {
        getHistoryImages();
        initialSuggestions();
    }, [company?.id]);
    
    useEffect(() => {
        setModel(getDriverModel("image")?.model);
    }, [company?.id, company?.ai_preferences]);
  
    useEffect(() => {
      resize();
    }, [query]);

    return (
        <div className='w-[auto] h-full flex flex-col lg:flex-row gap-8'>
            <Container className="flex-[0.35] flex flex-col gap-8">
                <div className="!relative lg:!sticky sticky lg:!top-6 !overflow-visible w-full z-[10] shadow-xl rounded-2xl">
                    <div id="anchorTop" className='flex items-center gap-4'>
                        <div className='flex-1 p-0 relative rounded-2xl bg-white dark:!bg-gray-700'> 
                            <div className="flex flex-col-reverse items-center">
                                <AIImageDropdownSizes 
                                    size={size}
                                    setSize={setSize}
                                    parentClassName="w-full"
                                    className="justify-center text-center w-full ml-0 !pt-4 !pb-4 !px-4 !pl-5 !pr-5 !bg-white !rounded-l-none dark:!bg-gray-700 !rounded-r-none border-t border-gray-100 dark:border-gray-600 !capitalize"
                                />
                                <AIImageDropdownStyles 
                                    style={style}
                                    setStyle={setStyle}
                                    parentClassName="w-full"
                                    className="justify-center text-center w-full ml-0 !pt-4 !pb-4 !px-4 !pl-5 !pr-5 !bg-white !rounded-l-none dark:!bg-gray-700 !rounded-r-none border-t border-gray-100 dark:border-gray-600 !capitalize"
                                />
                                <AIImageDropdownModels 
                                    model={model}
                                    setModel={setModel}
                                    parentClassName="w-full"
                                    className="justify-center text-center w-full ml-0 !pt-2.5 !pb-2.5 !px-4 !pl-5 !pr-5 !bg-white !rounded-l-none dark:!bg-gray-700 !rounded-r-none border-t border-gray-100 dark:border-gray-600 !capitalize"
                                />
                                <InputModern
                                    fieldsetClassName="!bg-gray-50 dark:!bg-gray-900 flex-1 !rounded-b-none"
                                    className="text-center max-h-[300px] overflow-auto !px-5 min-h-[100px] !py-5 !rounded-b-none !rounded-t-2xl !bg-gray-50 dark:!bg-gray-900 !text-sm !text-slate-800 dark:!text-slate-200" 
                                    textArea={true}
                                    value={query}
                                    ref={inputRef}
                                    placeholder={`Describe an image for Followr to generate`} 
                                    onChange={(e) => setQuery(e.target.value)} 
                                    onKeyUp={(e) => e.key == 'Enter' ? generateImages() : void 0}
                                />
                            </div>
                            <AIImageGenerateButton 
                                isLoading={isLoading}
                                query={query}
                                generateImages={generateImages}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center gap-6 w-full">
                    {!!suggestions?.length && suggestions?.map(suggestion => (
                        <p className="text-xs text-center group cursor-pointer" onClick={() => setQuery(suggestion)}>
                            <span className="group-hover:bg-gray-200 transition-all rounded">
                                {suggestion}
                            </span>
                        </p>
                    ))}
                    <Button 
                        variant="solid" 
                        onClick={() => initialSuggestions(true)}
                        loading={isLoadingSuggestions}
                    >
                        Regenerate suggestions
                    </Button>
                </div>
            </Container>
            {(!!historyImages?.length || isLoading || isLoadingHistory) && 
                <Container className="relative flex-1 overflow-y-auto min-h-full">
                    <div className={`relative flex flex-col gap-8 shrink-0 max-w-full lg:max-w-[250px] max-h-[512px] w-full ${1 == 1 && `!max-w-none !w-full !max-h-none`}`}>
                        {isLoadingHistory && !isLoading && !historyImages?.length
                            ? <ResponsiveMasonry columnsCountBreakPoints={columnBreakPoints} className={`w-full transition-all ${1 == 1 && `lg:!w-full lg:!max-w-none lg:!max-h-none`}`}>
                                <Masonry gutter='0.5rem'>
                                    {[1,2,4,5,3,2,1].map(r => (
                                        <div className={`relative flex items-center justify-center ${r % 3 == 0 ? `!aspect-square` : r % 3 == 1 ? `!aspect-[16/9]` : `!aspect-[9/16]`}`}>
                                            <Skeleton className={`w-full h-full absolute left-0 top-0 rounded-lg`} />
                                        </div>
                                    ))}
                                </Masonry>
                            </ResponsiveMasonry>
                            : <ResponsiveMasonry columnsCountBreakPoints={columnBreakPoints} className={`w-full transition-all ${1 == 1 && `lg:!w-full lg:!max-w-none lg:!max-h-none`}`}>
                                <Masonry gutter='0.5rem'>
                                    {isLoading && 
                                        <div className="relative flex items-center justify-center !aspect-square">
                                            <Skeleton className={`w-full h-full absolute left-0 top-0 rounded-lg`} />
                                            <Spinner size={40} />
                                        </div>
                                    }
                                    {isLoading && n > 1 && 
                                        <div className="relative flex items-center justify-center !aspect-square">
                                            <Skeleton className={`w-full h-full absolute left-0 top-0 rounded-lg`} />
                                            <Spinner size={40} />
                                        </div>
                                    }
                                    {isLoading && n > 2 && 
                                        <div className="relative flex items-center justify-center !aspect-square">
                                            <Skeleton className={`w-full h-full absolute left-0 top-0 rounded-lg`} />
                                            <Spinner size={40} />
                                        </div>
                                    }
                                    {isLoading && n > 3 && 
                                        <div className="relative flex items-center justify-center !aspect-square">
                                            <Skeleton className={`w-full h-full absolute left-0 top-0 rounded-lg`} />
                                            <Spinner size={40} />
                                        </div>
                                    }
                                    {historyImages?.map((result, key) => {
                                        result = {
                                            ...result,
                                            images: result?.images?.filter(e => e?.status == "analyzed")
                                        };
                                        const image = result?.images?.[0];
                                        const imageURL = result?.images?.[0]?.url || result?.response?.split(",")?.[0];
                                        const imageThumbnailURL = result?.images?.[0]?.thumbnail?.url || imageURL;
                                        return (
                                            <span 
                                                key={key}
                                                className={`${!!showPreview && `transition-all overflow-hidden`}`}
                                            >
                                                <MediaElement 
                                                    key={imageURL}
                                                    src={imageThumbnailURL}
                                                    originalSrc={imageURL}
                                                    handleOnSelect={handleOnSelect}
                                                    handleOnUnselect={handleOnUnselect}
                                                    selected={!!selectedImages?.find(e => e.originalSrc == imageURL)} 
                                                    isSelectable={isSelectable}
                                                    aspectRatio={getAspectRatio(image?.width, image?.height)}
                                                    isEditable={false}
                                                    mediaDescription={`Prompt: "${result?.query}"`}
                                                    aiImageModel={result?.model}
                                                    user={result?.user}
                                                    onError={(e) => {
                                                        try { e.target.parentElement.parentElement.style = "display: none" } catch (err) {}
                                                    }}
                                                />
                                            </span>
                                        );
                                    })}
                                </Masonry>
                            </ResponsiveMasonry>
                        }
                        {!!nextLink && 
                            <div className="w-full flex justify-center mt-6">
                                <Button 
                                    variant="solid"
                                    onClick={() => getHistoryImages(false)}
                                >
                                    Load more
                                </Button>
                            </div>
                        }
                    </div>
                </Container>
            }
        </div>
    )
}

export default AIImageBody;