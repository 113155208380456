import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Api from "services/Api";
import { SessionContext } from "./SessionContext";
import { PriorityModalContext } from "./PriorityModalContext";
import OutOfCompaniesModal from "components/layout/OutOfCompaniesModal";
import { socialNetworks } from "views/calendar/SocialNetworkSidePanel";

const CompanyContext = React.createContext({
    company: undefined,
    setCompany: (company) => void 0,
    companyUsers: [],
    companySocials: [],
    isLoadingCompanySocials: false,
    updateSocials: () => void 0,
    updateCompanyUsers: () => void 0
});

const CompanyProvider = ({ children }) => {

    const { user, session, updateBalance } = useContext(SessionContext);
    const [ isLoadingCompanySocials, setIsLoadingCompanySocials ] = useState(true);
    const companies = user?.companies;

    const [ companyUsers, setCompanyUsers ] = useState([]);
    const [ company, setCompany ] = useState(
        // localStorage.getItem(`company${user?.id}`) 
        // ? JSON.parse(localStorage.getItem(`company${user?.id}`))
        // : companies 
        //     ? localStorage.getItem(`actualCompanyID${user?.id}`) && companies?.filter(e => e.id == localStorage.getItem(`actualCompanyID${user?.id}`))?.[0]
        //         ? companies?.filter(e => e.id == localStorage.getItem(`actualCompanyID${user?.id}`))?.[0]
        //         : companies[0]
        //     : undefined
    );
    const [ companySocials, setCompanySocials ] = useState(
        localStorage.getItem(`companySocials${company?.id}`)
        ? JSON.parse(localStorage.getItem(`companySocials${company?.id}`))
        : []
    );

    const updateSocials = () => {
        if (company && session?.isLoggedIn) {
            try {
                Api.get(`/companies/${company?.id}/socialNetworks?include=image.thumbnail`).then(({ data: socials }) => {
                    socials = socials.map((e) => {
                        return {
                            ...e,
                            company_id: company?.id
                        }
                    })
                    setCompanySocials(socials.filter(e => socialNetworks.includes(e.type)));
                }).finally(() => {
                    setIsLoadingCompanySocials(false);
                });
            } catch(err) {
                setCompanySocials([]);
            }
        }
    }

    useEffect(() => {
        if (!window.location.href.includes("/onboarding")) {
            if (companies?.length && company) {
                setCompany(
                    companies?.filter(e => e.id == company.id)?.[0] 
                        ? companies?.filter(e => e.id == company.id)?.[0]
                        : companies?.length 
                            ? localStorage.getItem(`actualCompanyID${user?.id}`) 
                                ? companies?.filter(e => e.id == localStorage.getItem(`actualCompanyID${user?.id}`))?.[0]
                                : companies[0]
                            : undefined
                );
            }
        }
    }, [companies, company?.id, user]);

    useEffect(() => {
        if (company?.id && user?.id) {
            localStorage.setItem(`actualCompanyID${user?.id}`, company?.id);
        } 
        if (!company?.id && user?.id && window.location.pathname == "/onboarding") {
            localStorage.removeItem(`actualCompanyID${user?.id}`);
        } 
    }, [company?.id, user?.id]);

    useEffect(() => {
        // if (company) {
        //     localStorage.setItem(`company${user?.id}`, JSON.stringify(company));
        // } else {
        //     if (user) {
        //         localStorage.removeItem(`company${user?.id}`, JSON.stringify(company));
        //     }
        // }
    }, [company?.id]);

    useEffect(() => {
        if (!window.location.href.includes("/onboarding")) {
            if (companies?.length && !company) {
                setCompany(
                    localStorage.getItem(`actualCompanyID${user?.id}`) && companies?.filter(e => e.id == localStorage.getItem(`actualCompanyID${user?.id}`))?.[0]
                        ? companies?.filter(e => e.id == localStorage.getItem(`actualCompanyID${user?.id}`))?.[0]
                        : companies[0]
                );
            }
        }
    }, [companies]);

    useEffect(() => {
        if (company && companies) {
            setCompany(companies?.filter(e => e.id == company.id)?.[0]);
        }
        if (!companies?.length) {
            setCompany(undefined);
        }
    }, [companies]);

    const updateCompanyUsers = () => {
        if (company) {
            Api.get(`/users?filter[companies.id]=${company?.id}&include=image,roleUsers,subscriptionTokens`).then(({ data: _companyUsers }) => {
                setCompanyUsers(_companyUsers);
            });
        } else {
            setCompanyUsers([]);
        }
    }

    useEffect(() => {
        updateCompanyUsers();
    }, [company?.id]);

    useEffect(() => {
        setIsLoadingCompanySocials(true);
        setCompanySocials([]);
        updateSocials();
        updateBalance();
    }, [company?.id]);

    useEffect(() => {
        if (companySocials && company) {
            localStorage.setItem(`companySocials${company?.id}`, JSON.stringify(companySocials));
        } else {
            localStorage.removeItem(`companySocials${company?.id}`);
        }
        if (company) {
            if (companySocials && companySocials?.length) {
                window.Intercom('update', {
                    "has_social_network": true
                });
            } else {
                window.Intercom('update', {
                    "has_social_network": false
                });
            }
        }
    }, [company?.id, companySocials]);

    useEffect(() => {
        var url = new URL(window.location.href);
        var company_id = url.searchParams.get("company_id");
        if (company_id && companies && companies?.length > 0) {
            url.searchParams.delete("company_id");
            const _company = companies.filter(e => e.id == company_id);
            if (_company && _company?.length > 0) {
                setCompany(_company[0]);
            }
        }
    }, [companies]);

    // useEffect(() => {
    //     if (localStorage.getItem(`company${user?.id}`)) {
    //         let _company = JSON.parse(localStorage.getItem(`company${user?.id}`));
    //         if (user?.companies && user?.companies?.filter(c => c?.id == _company?.id)?.length > 0) {
    //             _company = user?.companies?.filter(c => c?.id == _company?.id)?.[0];
    //         }
    //         setCompany(_company);
    //     }
    // }, [user]);

    return (
        <CompanyContext.Provider value={{ 
            company: company, 
            setCompany: setCompany, 
            roles: [company?.role],
            companyUsers: companyUsers,
            companySocials: companySocials,
            isLoadingCompanySocials: isLoadingCompanySocials,
            updateSocials: updateSocials,
            updateCompanyUsers: updateCompanyUsers
        }}>
            {children}
        </CompanyContext.Provider>
    );
};

export { CompanyContext, CompanyProvider };