import React, { useContext, useEffect, useState } from "react";
import Api from "services/Api";
import { SessionContext } from "./SessionContext";
import { Notification, toast } from "components/ui";
import AppSumoModal from "components/layout/AppSumoModal";
import AppSumoWelcomeModal from "components/layout/AppSumoWelcomeModal";
import { CompanyContext } from "./CompanyContext";
import { ModalContext } from "./ModalContext";
import PlusModal from "components/shared/PlusModal";
import FreePlusModal from "components/shared/FreePlusModal";
import { PriorityModalContext } from "./PriorityModalContext";

const AppSumoContext = React.createContext({
    showAppSumoModal: false,
    setShowAppSumoModal: (value) => void 0,
    showAppSumoWelcomeModal: false,
    setShowAppSumoWelcomeModal: (value) => void 0,
    setShowFreePremiumImageModal: (value) => void 0,
    hasPremiumImages: () => true,
    isSumoling: () => true,
    isPlus: () => false,
    getTier: () => 0
});

const AppSumoProvider = ({ children }) => {

    const { openModalPriority } = useContext(PriorityModalContext);
    const { company, companyUsers } = useContext(CompanyContext);
    const { user, updateUserData } = useContext(SessionContext);
    const [ showAppSumoWelcomeModal, setShowAppSumoWelcomeModal ] = useState(false);
    const [ showAppSumoModal, setShowAppSumoModal ] = useState(false);
    const [ showFreePremiumImageModal, setShowFreePremiumImageModal ] = useState(false);
    const [ hasShownFreePremiumImagesModal, setHasShownFreePremiumImagesModal ] = useState(!!localStorage.getItem(`hasShownFreePremiumImagesModal`))
    const [ lastPremiumImagesSpentBalance, setLastPremiumImagesSpentBalance ] = useState(user?.balance?.premium_images_spent);
    const [ plan, setPlan ] = useState();

    const getTier = () => {
        if (!!companyUsers?.length) {
            const mainUser = companyUsers?.find(user => user?.id == company?.user?.id);
            if (mainUser) {
                return mainUser?.subscriptionTokens?.length;
            }
        }
        return 0;
    }

    const isPlus = () => {
        if (!!user?.subscription?.items?.find(i => i?.product?.name == `followr-plus`)) {
            return true;
        }
        return false;
    }

    const isSumoling = () => {
        if (!!companyUsers?.length) {
            return !!getTier() && !isPlus();
        }
        return true;
    }

    const hasPremiumImages = () => {
        if (!isSumoling()) return true;
        if (!user?.balance?.premium_images_spent && !hasShownFreePremiumImagesModal) return false;
        return (user?.balance?.premium_images_allowed || 0) - (user?.balance?.premium_images_spent || 0) > 0;
    }

	useEffect(() => {
		if (localStorage.getItem(`appsumo`)) {
            if (user?.id) {
                const appsumo = localStorage.getItem(`appsumo`);
                localStorage.removeItem(`appsumo`);
                Api.post(`/subscriptionTokens/redeem?include=items.product`, {}, { subscription_token: appsumo }).then((response) => {
                    setShowAppSumoModal(true);
                    setPlan(response?.data?.items?.[0]?.product?.label);
                    updateUserData();
                    window.Intercom('update', {
                        "subscription_token_plan": response?.data?.items?.[0]?.product?.name
                    });
                }).catch((err) => {
                    if (String(err?.message)?.includes(".exists")) {
                        toast.push(<Notification type="warning" title={`AppSumo: the redemption code doesn't exist. Try clicking the redeem button in AppSumo.`} />, { placement: "top-center" });
                    } else if (String(err?.message)?.includes("No query results for model")) {
                        toast.push(<Notification type="warning" title={`AppSumo: Your code has already been redeemed.`} />, { placement: "top-center" });
                    } else {
                        toast.push(<Notification type="warning" title={`AppSumo: ${err}`} />, { placement: "top-center" });
                    }
                })
            }
        } 
	}, [user?.subscription, user?.id]);

    useEffect(() => {
        if (
            !!isSumoling() && 
            user?.balance?.premium_images_spent >= user?.balance?.premium_images_allowed && 
            lastPremiumImagesSpentBalance !== undefined && 
            lastPremiumImagesSpentBalance < user?.balance?.premium_images_spent
        ) {
            openModalPriority(<PlusModal />, 650, true, true, "relative !overflow-visible !overflow-y-visible !p-0 !bg-transparent");
        }
        setLastPremiumImagesSpentBalance(user?.balance?.premium_images_spent);
    }, [user?.balance?.premium_images_spent]);

    useEffect(() => {
        if (!!companyUsers?.length) {
            if (
                !hasShownFreePremiumImagesModal && 
                !!isSumoling() && 
                user?.balance?.premium_images_allowed > user?.balance?.premium_images_spent && 
                user?.balance?.images_spent >= 5 && 
                !user?.balance?.premium_images_spent
            ) {
                setShowFreePremiumImageModal(true);
                setHasShownFreePremiumImagesModal(true);
                localStorage.setItem(`hasShownFreePremiumImagesModal`, `1`);
            }
        }
    }, [companyUsers?.length, user?.balance?.images_spent]);

    const url = new URL(window.location.href);
    const appsumo = url.searchParams.get("appsumo");
    if (appsumo && window.location.pathname != "/appsumo") {
        window.location.href = `/appsumo?code=${appsumo}`;
        return;
    }

    return (
        <AppSumoContext.Provider value={{
            showAppSumoModal,
            setShowAppSumoModal,
            showAppSumoWelcomeModal,
            setShowAppSumoWelcomeModal,
            setShowFreePremiumImageModal,
            hasPremiumImages,
            isSumoling,
            isPlus,
            getTier
        }}>
            {children}
            {showAppSumoModal && <AppSumoModal plan={plan} />}
            {showAppSumoWelcomeModal && <AppSumoWelcomeModal />}
            {showFreePremiumImageModal && <FreePlusModal />}
        </AppSumoContext.Provider>
    )
}

export { AppSumoContext, AppSumoProvider };