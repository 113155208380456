const aprox = function(v1, v2, epsilon) {
    if (epsilon == null) {
        epsilon = 0.001;
    }
    return Math.abs(v1 - v2) < epsilon;
};

export const getAspectRatio = (width, height) => {
    if (!width && !height) {
        return `aspect-square`;
    }
    if (aprox(width, height, width / 10)) {
        return `aspect-square`;
    } 
    if (parseInt(width) > parseInt(height)) {
        return `aspect-[16/10]`;
        // return `aspect-square`;
    }
    // return `aspect-square`;
    return `aspect-[10/16]`;
}

export const getCalendarDurationPerAspectRatio = (aspectRatio) => {
    return aspectRatio == "aspect-[10/16]" ? '02:30' : aspectRatio == "aspect-[16/10]" ? '01:15' : '01:30';
}

export const getHourDurationPerAspectRatio = (aspectRatio) => {
    return aspectRatio == "aspect-[10/16]" ? 2.5 : aspectRatio == "aspect-[16/10]" ? 1.25 : 1.5;
}