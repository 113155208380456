import { Button, Input } from "components/ui";
import InputModern from "components/ui/Input/InputModern";
import { MediaContext } from "contexts/MediaContext";
import React, { useContext, useState } from "react";
import { HiOutlineAdjustments, HiOutlineFilter, HiOutlinePhotograph, HiOutlineSearch } from "react-icons/hi";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import useDarkMode from "utils/hooks/useDarkMode";
import MediaElement from "./MediaElement";
import MediaUpload from "./MediaUpload";
import { ModalContext } from "contexts/ModalContext";
import ExploreMedia from "views/media/ExploreMedia";
import { Loading } from "components/shared";
import { Trans, useTranslation } from "react-i18next"; // Import the Trans component from react-i18next

const MyMedia = () => {
    const { myMedia, setFiltersMyMediaDrawerOpened, filtersMyMedia, setFiltersMyMedia, isLoadingMyMedia, hasMoreMyMedia, loadMoreMyMedia } = useContext(MediaContext);
    const { openModal } = useContext(ModalContext);
    const [query, setQuery] = useState(filtersMyMedia?.query);
    const { t } = useTranslation();

    return (
        <>
            <div className="w-full flex items-center gap-2">
                <div className='flex-1 w-auto mb-0 flex gap-0 items-center overflow-hidden rounded-xl relative'>
                    <div className='p-0 relative flex-1'> 
                        <InputModern 
                            onChange={(e) => setQuery(e.target.value)} 
                            className="!px-5 !py-5 !h-auto rounded-2xl bg-white rounded-2xl" 
                            placeholder="Search..." 
                            onKeyUp={(e) => e.key == 'Enter' ? setFiltersMyMedia({...filtersMyMedia, query: e.target.value }) : void 0}
                        />
                        <div className='flex gap-2 items-center !absolute right-2.5 top-1/2 translate-y-[-50%] '>
                            <div className='shrink-0'>
                                <Button 
                                    className="text-base !w-10 !h-10 !border-0 !rounded-2xl"
                                    onClick={() => setFiltersMyMediaDrawerOpened(true)} 
                                    icon={<HiOutlineFilter />} 
                                    filterQuantity={Object.keys(filtersMyMedia)?.length} 
                                />
                            </div>
                            <Button 
                                loading={isLoadingMyMedia}
                                variant="twoTone" 
                                className="text-base !w-10 !h-10 !border-0 !rounded-2xl"
                                onClick={() => setFiltersMyMedia({...filtersMyMedia, query: query })}
                                icon={<HiOutlineSearch />}
                            />
                        </div>
                    </div>
                    {/* <InputModern 
                        // label={<Trans i18nKey="myMedia.searchLabel">Search</Trans>}
                        size="sm" 
                        placeholder={t("myMedia.searchPlaceholder")} 
                        className="" 
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        onKeyDown={(e) => e.key == 'Enter' && setFiltersMyMedia({...filtersMyMedia, query: e.target.value})}
                    />
                    <Button
                        icon={<HiOutlineFilter />}
                        className="text-base !w-10 !h-10 !border-0 !absolute right-12 mr-1.5 top-3 mt-[3px] !rounded-2xl"
                        onClick={() => setFiltersMyMediaDrawerOpened(true)}
                        filterQuantity={Object.keys(filtersMyMedia)?.length}
                    />
                    <Button
                        variant="twoTone"
                        icon={<HiOutlineSearch />}
                        onClick={() => setFiltersMyMedia({...filtersMyMedia, query: query})}
                        loading={isLoadingMyMedia}
                        className="text-base !w-10 !h-10 !absolute right-1.5 mr-1.5 top-3 mt-[3px] !rounded-2xl"
                    /> */}
                </div>
            </div>
            {/* <Button onClick={() => openModal(<ExploreMedia />, 1200, true, true)} variant="solid" icon={"📸"}>
                &nbsp; <Trans i18nKey="myMedia.exploreMedia">Explore media</Trans>
            </Button> */}
            {(myMedia && myMedia?.length > 0) && 
                <ResponsiveMasonry columnsCountBreakPoints={{350: 1}}>
                    <Masonry gutter='0.33rem'>
                        {myMedia.map((item, key) => (
                            <MediaElement asset={item} key={key} src={item?.src} originalSrc={item?.original} aspectRatio={item?.aspectRatio} name={item?.name} isDraggable={true} id={item?.id} />
                        ))}
                    </Masonry>
                </ResponsiveMasonry>
            }
            <div className='!text-xs text-gray-400 dark:text-gray-600 text-center h-full flex-1 flex flex-col justify-center items-center w-full'>
                {myMedia && myMedia?.length > 0 
                    ? hasMoreMyMedia 
                        ? <Button loading={isLoadingMyMedia} disabled={isLoadingMyMedia} variant="twoTone" onClick={loadMoreMyMedia}><Trans i18nKey="myMedia.loadMore">Load More</Trans></Button>
                        : <>
                            <Trans i18nKey="myMedia.noMoreItems">No more items</Trans>
                            <br /><Trans i18nKey="myMedia.dragDropUpload">Drag and drop to upload more.</Trans>
                        </> 
                    : !isLoadingMyMedia && <div className="flex flex-1 flex-col items-center justify-center gap-4 w-full">
                        <MediaUpload className="w-full" />
                        <img src={`/img/others/nothing-to-see-3.png`} className="w-24 drop-shadow my-5 m-auto" />
                        <span>
                            <Trans i18nKey="myMedia.nothingToSee">Nothing to see here.</Trans><br />
                            {Object.keys(filtersMyMedia)?.length > 0 
                                ? <a className="text-primary-600 cursor-pointer" onClick={() => setFiltersMyMedia({})}><Trans i18nKey="myMedia.removeFilters">Remove filters</Trans></a>
                                : <Trans i18nKey="myMedia.dragDropUpload">Drag and drop to upload more.</Trans>
                            }
                        </span>
                    </div>
                }
                {isLoadingMyMedia && <div className="block my-16 text-center"><Loading type={'element'} /></div>}
            </div>
        </>
    )
}

export default MyMedia;
