import { DoubleSidedImage } from "components/shared";
import { Button, FormItem, Upload } from "components/ui";
import { CompanyContext } from "contexts/CompanyContext";
import { MediaContext } from "contexts/MediaContext";
import { UploadContext } from "contexts/UploadContext";
import { Field } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { isDragSourceExternalFile } from "utils/isExternalFile";

const MediaUpload = ({className = "", buttonClassName = "", invisible = false, isButton = false}) => {

    const { addFilesUploadModal } = useContext(UploadContext);
    const { company } = useContext(CompanyContext);
    const [ show, setShow ] = useState(false);

    const beforeUpload = () => {
        return true;
    }

    const onUpload = (files) => {
        addFilesUploadModal(files);
    }

    useEffect(() => {
        if (invisible) {
            document.body.addEventListener('dragenter', (e) => {
                if (isDragSourceExternalFile(e.dataTransfer)) {
                    setShow(true);
                }
            });
            document.body.addEventListener('dragleave', (e) => {
                if (e.pageX == 0 && e.pageY == 0) {
                    setShow(false);
                }
            });
            document.body.addEventListener('drop', () => {
                setShow(false);
            });
        }
    }, []);

    if (!company) return <></>;

    return (
        <Upload
            beforeUpload={(beforeUpload)}
            onChange={(files) =>
                onUpload(files)
            }
            showList={false}
            multiple={true}
            removeFilesOnUpload={true}
            draggable
            accept="image/*,video/*"
            className={`py-0 ${className} ${isButton && `!border-0 !h-auto !min-h-0 cursor-pointer`} ${!isButton && `rounded-2xl border-gray-200`} ${invisible ? `w-full h-full absolute left-0 top-0 !border-transparent dark:!border-transparent !rounded-none` : ``} ${show && invisible ? `!border-[4px] !border-primary-600 !border-dashed bg-gray-900/90` : !show && invisible ? `pointer-events-none` : ``}`}
        > 
            {!invisible && !isButton && 
                <div className="my-8 mt-7 text-center">
                    <img
                        className="mx-auto h-10 mb-1"
                        src="/img/others/upload.png"
                    />
                    <p className="font-semibold !text-xs">
                        <span className="text-gray-800 dark:text-gray-200 px-4">
                            <Trans i18nKey={`mediaUpload.dragAndDrop`}>Drag and drop and media file to upload it!</Trans>
                        </span>
                    </p>
                    <p className="mt-1 opacity-60 dark:text-white text-xs">
                        <Trans i18nKey={`mediaUpload.orClickToBrowse`}>Or click to browse</Trans>
                    </p>
                </div>
            }
            {!invisible && isButton && <>
                <Button className={`hidden lg:block pointer-events-none ${buttonClassName}`} icon={<AiOutlineCloudUpload />}>&nbsp; <Trans i18nKey={`mediaUpload.uploadMedia`}>Upload Media</Trans></Button>
                <Button className={`lg:hidden !px-6 pointer-events-none ${buttonClassName}`} icon={<AiOutlineCloudUpload />}><Trans i18nKey={`mediaUpload.upload`}>Upload</Trans></Button>
            </>}
            {invisible && 
                <></>
            }
            {invisible && show &&
                <div className="flex flex-col justify-center items-center gap-6">
                    <img src="/img/others/file-upload.png" className="max-w-[200px]"></img>
                    <div className="flex flex-col gap-2">
                        <h2 className="!text-white mega-title text-center"><Trans i18nKey={`mediaUpload.dragAndDropAnyFiles`}>Drag & drop any files</Trans></h2>
                        <p className="text-center !text-white"><Trans i18nKey={`mediaUpload.filesWillBeUploaded`}>Files will be uploaded to "My Assets"</Trans></p>
                    </div>
                </div>
            }
        </Upload>
    );
}

export default MediaUpload;