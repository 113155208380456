import React, { ReactNode, useContext, useEffect, useState } from "react";
import { CompanyContext } from "./CompanyContext";
import { useSocialNetworks } from "utils/hooks/socials/useSocialNetworks";
import { CalendarActiveSocialNetworkContext } from "./CalendarActiveSocialNetworkContext";
import useLazyEffect from "utils/hooks/useLazyEffect";
import Api from "services/Api";
const ConversationContext = React.createContext({
    conversations: [],
    isLoadingConversations: false,
    updateConversationFields: (external_id, obj) => void 0,
    updateConversations: () => void 0,
    updateLastReadAt: (_conversation) => void 0
});

const ConversationProvider = ({ children }) => {

    const initialStateFilters = {
        query: "",
        assignedTo: false,
        unread: false
    };

    const { company, companySocials } = useContext(CompanyContext);
    const { calendarActiveSocialNetwork } = useContext(CalendarActiveSocialNetworkContext);
    const { getConversations } = useSocialNetworks();
    const [ conversations, setConversations ] = useState();
    const [ filteredConversations, setFilteredConversations ] = useState();
    const [ isLoadingConversations, setIsLoadingConversations ] = useState();
    const [ filters, setFilters ] = useState(initialStateFilters);
    
    const updateConversationFields = (external_id, obj) => {
        let _newConversations = conversations.map(l => Object.assign({}, l));
        _newConversations = _newConversations?.map(x => (x.id === external_id ? { ...x, ...obj } : x));
        setSortedConversations(_newConversations);
        return _newConversations;
    }
    
    const setSortedConversations = (conversations) => {
        let _conversations = conversations.map(l => Object.assign({}, l));
        _conversations = _conversations.sort((a,b) => new Date(b.lastMessage.created_at) - new Date(a.lastMessage.created_at));
        _conversations = _conversations.sort((a,b) => b.unreadMessages_count - a.unreadMessages_count);
        setConversations(_conversations);
    }
    
    const updateConversations = () => {
        if (company && companySocials && companySocials?.length && company?.id == companySocials?.[0]?.company_id) {
            setIsLoadingConversations(true);
            getConversations(filters).then((conversations) => {
                setSortedConversations(conversations);
            }).finally(() => {
                setIsLoadingConversations(false);
            });
        } else {
            setSortedConversations([]);
        }
    }

    const updateLastReadAt = (_conversation) => {
        const _newConversations = updateConversationFields(_conversation?.id, { unreadMessages_count: 0 });
        try {
            Api.put(`/conversations/${_conversation?.id}`, {}, { update_last_read_at: true }).then((response) => response);
        } catch (err) {}
        return _newConversations;
    }

    useEffect(() => {
        updateConversations();
    }, [companySocials?.[0]?.id]);

    useEffect(() => {
        const interval = setInterval(() => {
            updateConversations();
        }, 30 * 1000);
        return () => clearInterval(interval)
    }, [companySocials?.[0]?.id, filters]);

    useEffect(() => {
        setConversations(undefined);
        updateConversations();
    }, [filters.unread, filters.assignedTo]);

    useLazyEffect(() => {
        setConversations(undefined);
        updateConversations();
    }, [filters.query], 400)

    return (
        <ConversationContext.Provider value={{ 
            conversations,
            isLoadingConversations,
            updateConversationFields,
            updateConversations,
            updateLastReadAt,
            filters,
            setFilters
        }}>
            {children}
        </ConversationContext.Provider>
    );
};

export { ConversationContext, ConversationProvider };