import PlusBadge from "components/shared/PlusBadge";
import capitalize from "components/ui/utils/capitalize";
import { PLACEMENT } from "components/ui/utils/constant";
import { AIContext } from "contexts/AIContext";
import { useContext } from "react";
import { HiCheckCircle } from "react-icons/hi";

const { Dropdown, Button } = require("components/ui");

const AIImageDropdownModels = ({
    model,
    setModel,
    className = "",
    parentClassName = "",
    variant = undefined
}) => {

    const { aiImageModels, availableAiModels } = useContext(AIContext);
    const selectedModel = availableAiModels?.find(e => e.model == (model || process.env.REACT_APP_DEFAULT_IMAGE_AI_MODEL));

    if (aiImageModels?.length <= 1) return <></>;

    return (
        <Dropdown
            menuClass="max-h-[290px] w-[240px] overflow-y-auto !rounded-2xl !border-0 !p-0"
            placement={PLACEMENT.CENTER_CENTER}
            parentClassName={parentClassName}
            renderTitle={
                <Button 
                    variant={variant}
                    className={`cursor-pointer !pl-5 overflow-hidden whitespace-nowrap !px-10 !py-3 !h-auto bg-gray-50 dark:bg-gray-600 rounded-2xl flex items-center gap-2.5 font-semibold ${className}`}
                >
                    <img src={selectedModel?.image} className="h-5 rounded" />
                    <div className="flex flex-col justify-start items-start">
                        {selectedModel?.fullName}
                        <small className="text-gray-400 dark:text-gray-500 lowercase">{selectedModel?.price} image credit{selectedModel?.price > 1 ? `s` : ``}</small>
                    </div>
                </Button>
            }
        >
            {aiImageModels?.map((_model, key) => (
                <Dropdown.Item key={key} className="!px-0">
                    <div 
                        onClick={(e) => !_model?.disabled ? setModel(_model?.model) : e?.stopPropagation()} 
                        className={`
                            flex justify-between items-center gap-2.5 !px-4 !py-2 w-full 
                            ${!_model?.disabled 
                                ? `cursor-pointer dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600`
                                : `cursor-normal cursor-default`
                            }
                        `}
                    >
                        <div className={`flex items-center gap-2 ${_model?.disabled && `opacity-30`}`}>
                            <img src={_model?.image} className="h-5 rounded" />
                            <div className="flex flex-col">
                                {_model?.fullName}
                                <small className="text-gray-400 dark:text-gray-500">{_model?.price} image credit{_model?.price > 1 ? `s` : ``}</small>
                            </div>
                        </div>
                        {!_model?.disabled
                            ? <HiCheckCircle className={`text-xl text-primary-600 shrink-0 ${_model?.model != model && `opacity-[0.1]`}`} />
                            : <PlusBadge 
                                wrapperClassName={`shrink-0 -mr-0.5`}
                                className={`text-2xl`} 
                                feature={_model?.fullName}
                                placement="right"
                            />
                        }
                        
                    </div>
                </Dropdown.Item>
            ))}
        </Dropdown>
    );
}

export default AIImageDropdownModels;