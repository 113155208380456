import { Dropdown } from 'components/ui';
import React from 'react'
import { HiCheckCircle } from 'react-icons/hi';
import useConfigPanel from 'utils/hooks/useConfigPanel';

export default function ConfigPanelPostTypesOptions({
  postType = undefined,
  hideChecks = undefined,
  setPostType
}) {

  const { postTypes } = useConfigPanel();

  return (
    <>
      {postTypes?.map((_postType, key) => (
          <Dropdown.Item className="!px-0">
            <div onClick={() => setPostType(_postType?.value)} key={key} className="flex justify-between items-center gap-2 !px-4 !py-2 cursor-pointer dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600 w-full">
              <div className='flex items-center gap-2'>
                <span className="shrink-0">{_postType?.emoji}</span>
                <span className="shrink-0">{_postType?.name}</span>
              </div>
              {!hideChecks && <HiCheckCircle className={`text-xl text-primary-600 shrink-0 ${_postType?.value != postType && `opacity-[0.1]`}`} />}
            </div>
          </Dropdown.Item>
      ))}
    </>
  )
}
