import { Loading } from "components/shared";
import { Button } from "components/ui";
import InputModern from "components/ui/Input/InputModern";
import React, { useContext, useEffect, useRef, useState } from "react";
import { HiOutlineCpuChip } from "react-icons/hi2";
import { AIContext } from "contexts/AIContext";


import usePrompts from "utils/hooks/usePrompts";
import usePostValidator from "utils/hooks/usePostValidator";

export const socialNetworkPrompts = {
    "instagram": "copy",
    "facebook": "copy",
    "twitter": "copy",
    "linkedin": "copy",
    "tiktok": "copy",
    "discord": "copy",
    "medium": "blog",
    "pinterest": "copy",
    "youtube": "copy",
    "telegram": "copy",
}

export const socialNetworkPromptsSuffix = {
    "instagram": `
        
        INSTRUCTIONS:
        - Max 1200 characters
        - Min 150 characters

        Text: 
    `,
    "facebook": `
        
        INSTRUCTIONS:
        - Max 1200 characters
        - Min 150 characters

        Text: 
    `,
    "twitter": `

        INSTRUCTIONS:
        - Max 250 characters
        - Min 150 characters

        Text: 
    `,
    "linkedin": `
        
        INSTRUCTIONS:
        - Max 1200 characters
        - Min 250 characters

        Text: 
    `,
    "tiktok": `
        
        INSTRUCTIONS:
        - Max 250 characters
        - Min 25 characters

        Text: 
    `,
    "discord": `
    
    `,
    "medium": `
    
        Answer in HTML using ONLY the following tags if needed: h1, h2, p, blockquote, b, strong, i, em, a, hr, br.
        Do not use <style> tag.

        Blog post:
    `,
    "pinterest": `
            
        INSTRUCTIONS:
        - Max 500 characters
        - Min 250 characters 

        Text: 
    `,
    "youtube": ``,
    "telegram": ``,
}

const AdaptToSocialNetwork = ({ network, textToAdapt, setValue, setMenuOpened }) => {

    const [ isLoading, setIsLoading ] = useState(false);
    const { getByQueryChat } = useContext(AIContext);
    const { validationConstants } = usePostValidator();
    let value = "";

    useEffect(() => {
        setIsLoading(true);
        if (validationConstants?.[network]?.description?.max_length) {
            let query = `
                Given the following text: "${textToAdapt}"

                Write a ${socialNetworkPrompts[network]}.
                ${socialNetworkPromptsSuffix[network]}
            `;
            getByQueryChat(query).then((response) => {
                setValue(response);
            }).finally(() => {
                setIsLoading(false);
                setMenuOpened(false);
            })
        }
    }, [validationConstants]);

    return (
        <div className="flex items-center justify-center p-4 gap-2">
            <Loading type={'element'} /> 
        </div>
    )
}

export default AdaptToSocialNetwork;