import { Loading } from 'components/shared';
import { Button, Dropdown, Input, Tabs } from 'components/ui';
import InputModern from 'components/ui/Input/InputModern';
import TabContent from 'components/ui/Tabs/TabContent';
import TabList from 'components/ui/Tabs/TabList';
import TabNav from 'components/ui/Tabs/TabNav';
import { CompanyContext } from 'contexts/CompanyContext';
import { MediaContext } from 'contexts/MediaContext';
import React, { useContext, useEffect, useState } from 'react'
import { Trans } from 'react-i18next';
import { HiOutlineChip, HiOutlineFilter, HiOutlinePhotograph } from 'react-icons/hi';
import { HiOutlineMagnifyingGlass, HiOutlineRectangleGroup } from 'react-icons/hi2';
import InfiniteScroll from 'react-infinite-scroller';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import getFileType from 'utils/fileType';
import { useStockMedia } from 'utils/hooks/useStockMedia';
import { getGiphyImages, getPexelsVideos, getUnsplashImages } from 'utils/stockImages';
import MediaElement from 'views/calendar/MediaElement';
import MediaUpload from 'views/calendar/MediaUpload';
import { IoIosImages } from "react-icons/io";
import { MdImageSearch } from 'react-icons/md';
import { BsStars } from 'react-icons/bs';
import AIImageBody from 'views/ai_image/AIImageBody';
import capitalize from 'components/ui/utils/capitalize';
import { LuImage, LuRectangleHorizontal, LuRectangleVertical } from 'react-icons/lu';
import { TbGif, TbPhotoVideo, TbSquare, TbVideo } from 'react-icons/tb';

const ExploreMedia = ({ 
    isSelectable = false, 
    isMultipleSelect = false,
    handleOnSelect = undefined, 
    handleOnUnselect = undefined, 
    initialSelectedAssets = undefined,
    allowedTypes = ["image", "video", "gif"], 
    allowedOrientations = ["landscape", "portrait", "square"],
    showMyAssets = false, 
    showAIAssets = true,
    showStockAssets = true,
    className = "",
    breakpoints = { 350: 1, 512: 2, 750: 2, 900: 3, 1100: 5, 1600: 5 },
    stockInputClassName = "" 
}) => {  

    const perPage = 30;
    const {company} = useContext(CompanyContext);
    const [media, setMedia] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { isLoadingMyMedia, myMedia, filtersMyMedia, setFiltersMyMedia, setFiltersMyMediaDrawerOpened, hasMoreMyMedia, loadMoreMyMedia } = useContext(MediaContext);
    const [query, setQuery] = useState("");
    const [myMediaQuery, setMyMediaQuery] = useState(filtersMyMedia?.query || "");
    const [page, setPage] = useState(0);
    const [selectedAssets, setSelectedAssets] = useState([]);
    const [filteredType, setFilteredType] = useState(allowedTypes?.length == 3 ? undefined : allowedTypes[0]);
    const [filteredOrientation, setFilteredOrientation] = useState(allowedOrientations?.length == 3 ? undefined : allowedOrientations[0])
    const { getStockMedia } = useStockMedia();

    const getMedia = (replace = false, _page = page) => {
        _page = _page+1;
        if (replace) {
            _page = 1;
            setMedia([]);
        };
        setPage(_page);
        if (!isLoading) {
            setIsLoading(true);
            getStockMedia(perPage, _page, query, filteredOrientation, allowedTypes?.filter(e => filteredType ? e == filteredType : true)).then((data) => {
                setMedia(replace ? [...(data ?? [])] : [...media, ...(data ?? [])]);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }

    const prevHandleOnSelect = (originalSrc, asset) => {
        if (!isMultipleSelect) {
            handleOnSelect(originalSrc, asset)
        } else {
            if (!!handleOnSelect(originalSrc, asset)) {
                setSelectedAssets([...selectedAssets, { originalSrc, asset }]);
            }
        }
    }

    const prevHandleOnUnselect = (originalSrc, asset) => {
        if (isMultipleSelect) {
            setSelectedAssets(selectedAssets.filter(i => i.originalSrc != originalSrc));
            !!handleOnUnselect && handleOnUnselect(originalSrc, asset);
        }
    }

    useEffect(() => {
        getMedia(true, 0);
    }, [filteredType, filteredOrientation, company?.id]);

    useEffect(() => {
        // if (!selectedAssets?.length) {
        setSelectedAssets(initialSelectedAssets || []);
        // }
    }, [initialSelectedAssets]);

    // useEffect(() => {
    //     if (media) {
    //         localStorage.setItem(`mediaMedia${company?.id}${JSON.stringify(allowedTypes)}`, JSON.stringify(media));
    //     }
    // }, [media]);

    return (
        <>
            <Tabs defaultValue={showMyAssets ? `tabMyMedia` : showStockAssets ? `tabExplore` : `tabAI`} variant="pill">
                <TabList className={`flex items-stretch flex-col md:flex-row !mb-8 ${!showMyAssets && `!hidden`}`}>
                    {showMyAssets && <TabNav value="tabMyMedia" className="flex-grow grow flex-1 !text-sm m-0 rounded-2xl py-[13px] flex items-center justify-center gap-1">
                        <HiOutlinePhotograph className='text-base' />
                        <span className='!text-[12px]'><Trans i18nKey="exploreMedia.myAssets">My Assets</Trans></span>
                    </TabNav>}
                    {showStockAssets && <TabNav value="tabExplore" className="flex-grow grow flex-1 !text-sm m-0 rounded-2xl py-[13px] flex items-center justify-center gap-1">
                        <MdImageSearch className='text-base' />
                        <span className='!text-[12px]'><Trans i18nKey="exploreMedia.explore">Stock Images</Trans></span>
                    </TabNav>}
                    {showAIAssets && <TabNav value="tabAI" className="flex-grow grow flex-1 !text-sm m-0 rounded-2xl py-[13px] flex items-center justify-center gap-1">
                        <BsStars className='text-base' />
                        <span className='!text-[12px]'><Trans i18nKey="exploreMedia.aiGenerated">AI Generated</Trans></span>
                    </TabNav>}
                </TabList>
                <div className='p-4 !p-0'>
                    <TabContent className="flex flex-col gap-4" value="tabMyMedia">
                        <div className='w-[auto] h-full !overflow-y-auto'>
                            <div className='pb-6'>
                                <MediaUpload />
                            </div>
                            <div className='flex items-center pb-4 gap-2'>
                                <div className='p-0 relative flex-1'> 
                                    <InputModern 
                                        onChange={(e) => setMyMediaQuery(e.target.value)} 
                                        className="!px-5 !py-5 !h-auto rounded-2xl" 
                                        placeholder="Search..." 
                                        onKeyUp={(e) => e.key == 'Enter' ? setFiltersMyMedia({...filtersMyMedia, query: myMediaQuery }) : void 0}
                                    />
                                    <div className='flex gap-2 items-center !absolute right-2.5 top-1/2 translate-y-[-50%] '>
                                        <Button 
                                            loading={isLoading}
                                            variant="" 
                                            size="md" 
                                            className="flex gap-2 items-center !text-xs"
                                            onClick={() => setFiltersMyMedia({...filtersMyMedia, query: myMediaQuery })}
                                        ><HiOutlineMagnifyingGlass className='text-xl' /><span>Search</span></Button>
                                        <div className='shrink-0'>
                                            <Button className="hidden lg:block" onClick={() => setFiltersMyMediaDrawerOpened(true)} icon={<HiOutlineFilter />} filterQuantity={Object.keys(filtersMyMedia)?.length}>&nbsp; <Trans i18nKey={`media.filters`}>Filters</Trans></Button>
                                            <Button className="lg:hidden" onClick={() => setFiltersMyMediaDrawerOpened(true)} icon={<HiOutlineFilter />} filterQuantity={Object.keys(filtersMyMedia)?.length} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isLoading && media?.length == 0 && <Loading type={`element`} />}
                            <ResponsiveMasonry className='my-4 mt-0' columnsCountBreakPoints={breakpoints}>
                                <Masonry gutter='0.75rem'>
                                    {myMedia && myMedia.map((item, key) => {
                                    if (!allowedTypes?.includes(getFileType(item?.original))) return <></>;
                                        return (
                                            <MediaElement 
                                                handleOnSelect={prevHandleOnSelect} 
                                                handleOnUnselect={prevHandleOnUnselect} 
                                                selected={!!selectedAssets.find(e => e?.asset?.id == item?.id)} 
                                                isSelectable={isSelectable} 
                                                key={key} 
                                                asset={item} 
                                                onError={(e) => {
                                                    try { e.target.parentElement.parentElement.style = "display: none" } catch (err) {}
                                                }}
                                            />
                                        );
                                    })}
                                </Masonry>
                            </ResponsiveMasonry>
                            {hasMoreMyMedia && <div className='flex w-full justify-center my-4'><Button loading={isLoadingMyMedia} disabled={isLoadingMyMedia} variant="twoTone" onClick={loadMoreMyMedia}>Load More</Button></div>}
                        </div>
                    </TabContent>
                    <TabContent className="flex flex-col gap-4" value="tabExplore">
                        <div className='p-0 relative pb-4 flex flex-col md:flex-row items-stretch md:items-center'> 
                            <Dropdown
                                renderTitle={
                                    <Dropdown.Item className={`flex items-center gap-2.5 py-2 px-4 !h-14 border-b md:border-b-none md:border-b-0 md:border-r border-gray-100 dark:border-gray-600 rounded-b-none rounded-t-2xl md:!rounded-r-none md:rounded-l-2xl bg-gray-100 dark:bg-gray-700 ${stockInputClassName}`}>
                                        {filteredType == "image" 
                                            ? <LuImage className='text-2xl text-primary-600' />
                                            : filteredType == "gif" 
                                                ? <TbGif className='text-2xl text-purple-500' />
                                                : filteredType == "video"
                                                    ? <TbVideo className='text-2xl text-fuchsia-600' />
                                                    : <TbPhotoVideo className='text-2xl' />
                                        }
                                        <span>{filteredType ? capitalize(filteredType) : "All Media"}</span>
                                    </Dropdown.Item>
                                }
                            >
                                <Dropdown.Item onClick={() => setFilteredType(undefined)} className="flex items-center gap-2.5 py-2 px-4">
                                    <TbPhotoVideo className='text-2xl' />
                                    <span>All Media</span>
                                </Dropdown.Item>
                                {allowedTypes?.map((item, key) => (
                                    <Dropdown.Item key={key} onClick={() => setFilteredType(item)} className="flex items-center gap-2.5 py-2 px-4">
                                        {item == "image" 
                                            ? <LuImage className='text-2xl text-primary-600' />
                                            : item == "gif" 
                                                ? <TbGif className='text-2xl text-purple-500' />
                                                : item == "video"
                                                    ? <TbVideo className='text-2xl text-fuchsia-600' />
                                                    : <></>
                                        }
                                        <span>{capitalize(item)}</span>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown>
                            <Dropdown
                                renderTitle={
                                    <Dropdown.Item className={`flex items-center gap-2.5 py-2 px-4 !h-14 border-b md:border-b-none md:border-b-0 md:border-r border-gray-100 dark:border-gray-600 !rounded-none bg-gray-100 dark:bg-gray-700 ${stockInputClassName}`}>
                                        {filteredOrientation == "square" 
                                            ? <TbSquare className='text-2xl text-primary-600' />
                                            : filteredOrientation == "landscape" 
                                                ? <LuRectangleHorizontal className='text-2xl text-purple-500' />
                                                : filteredOrientation == "portrait"
                                                    ? <LuRectangleVertical className='text-2xl text-fuchsia-600' />
                                                    : <HiOutlineRectangleGroup className='text-2xl' />
                                        }
                                        <span>{filteredOrientation ? capitalize(filteredOrientation) : "All Orientations"}</span>
                                    </Dropdown.Item>
                                }
                            >
                                <Dropdown.Item onClick={() => setFilteredOrientation(undefined)} className="flex items-center gap-2.5 py-2 px-4">
                                    <HiOutlineRectangleGroup className='text-2xl' />
                                    <span>All Orientations</span>
                                </Dropdown.Item>
                                {allowedOrientations?.map((item, key) => (
                                    <Dropdown.Item key={key} onClick={() => setFilteredOrientation(item)} className="flex items-center gap-2.5 py-2 px-4">
                                        {item == "square" 
                                            ? <TbSquare className='text-2xl text-primary-600' />
                                            : item == "landscape" 
                                                ? <LuRectangleHorizontal className='text-2xl text-purple-500' />
                                                : item == "portrait"
                                                    ? <LuRectangleVertical className='text-2xl text-fuchsia-600' />
                                                    : <></>
                                        }
                                        <span>{capitalize(item)}</span>
                                    </Dropdown.Item>
                                ))}
                            </Dropdown>
                            <InputModern 
                                onChange={(e) => setQuery(e.target.value)} 
                                className={`!px-5 !py-5 !h-14 !rounded-none text-sm font-semibold ${stockInputClassName}`} 
                                fieldsetClassName="flex-1 !rounded-none"
                                placeholder="Search..." 
                                onKeyUp={(e) => e.key == 'Enter' ? getMedia(true) : void 0}
                            />
                            <Button 
                                loading={isLoading}
                                variant="ai" 
                                size="md" 
                                className="flex gap-2 items-center justify-center font-semibold !h-14 !py-4 !rounded-t-none md:!rounded-tr-2xl md:!rounded-l-none !px-4"
                                onClick={() => getMedia(true)}
                            >
                                <HiOutlineMagnifyingGlass className='text-xl' />
                                <span>Search</span>
                            </Button>
                        </div>
                        {isLoading && media?.length == 0 && <Loading type={`element`} />}
                        <div className='w-[auto] h-full !overflow-y-auto'>
                            <ResponsiveMasonry className='my-4 mt-0' columnsCountBreakPoints={breakpoints}>
                                <Masonry gutter='0.75rem'>
                                    {!!media?.length && media.map((item, key) => {
                                        return (
                                            <MediaElement 
                                                handleOnSelect={prevHandleOnSelect} 
                                                handleOnUnselect={prevHandleOnUnselect} 
                                                selected={!!selectedAssets?.find(e => e.originalSrc == item?.original)} 
                                                isSelectable={isSelectable} 
                                                key={key} 
                                                aspectRatio={item?.aspectRatio} 
                                                src={item?.src} 
                                                originalSrc={item?.original} 
                                                mediaDescription={item?.description} 
                                                isStock={item?.isStock} 
                                                onError={(e) => {
                                                    try { e.target.parentElement.parentElement.style = "display: none" } catch (err) {}
                                                }}
                                                {...item} 
                                            />
                                        )
                                    })}
                                </Masonry>
                            </ResponsiveMasonry>
                            {!!media?.length && <div className='flex w-full justify-center my-4'><Button variant="twoTone" loading={isLoading} onClick={() => getMedia(false)}>Load More</Button></div>}
                        </div>
                    </TabContent>
                    <TabContent className="flex flex-col gap-4" value="tabAI">
                        <AIImageBody 
                            inputClassName='!bg-gray-100 dark:!bg-gray-700 !ring-transparent'
                            columnBreakPoints={{ 350: 2, 1024: 3 }}
                            showPreview={false}
                            handleOnSelect={prevHandleOnSelect} 
                            handleOnUnselect={prevHandleOnUnselect} 
                            selectedImages={selectedAssets}
                            isSelectable={isSelectable} 
                        />
                    </TabContent>
                </div>
            </Tabs>
        </>
    );
}

export default ExploreMedia;