import { PLACEMENT } from "components/ui/utils/constant";
import { aiSizeOptions } from "contexts/AIContext";
import { HiCheckCircle } from "react-icons/hi";

const { Dropdown } = require("components/ui");

const AIImageDropdownSizes = ({
    size,
    setSize,
    className = "",
    parentClassName = ""
}) => {

    return (
        <Dropdown
            menuClass="max-h-[290px] w-[240px] overflow-y-auto !rounded-2xl !border-0 !p-0"
            placement={PLACEMENT.BOTTOM_START}
            parentClassName={parentClassName}
            renderTitle={
                <div className={`cursor-pointer !pl-5 overflow-hidden whitespace-nowrap !px-10 !py-3 !h-auto bg-gray-50 dark:bg-gray-600 rounded-2xl flex items-center font-semibold ${className}`}>
                    {aiSizeOptions?.filter(e => e.value == size)?.[0]?.label || aiSizeOptions?.[0]?.label}
                </div>
            }
        >
            {aiSizeOptions?.map((_size, key) => (
                <Dropdown.Item key={key} className="!px-0">
                    <div onClick={() => setSize(_size?.value)} className="flex justify-between items-center gap-2 !px-4 !py-2 cursor-pointer dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600 w-full">
                        <div className='flex items-center gap-2'>
                            <span>{_size?.label}</span>
                        </div>
                        <HiCheckCircle className={`text-xl text-primary-600 shrink-0 ${_size?.value != size && `opacity-[0.1]`}`} />
                    </div>
                </Dropdown.Item>
            ))}
        </Dropdown>
    );
}

export default AIImageDropdownSizes;