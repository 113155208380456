import axios from "axios";
import Api from "services/Api";

export const getImageAICaptionFile = async (file) => {
    var formData = new FormData();
    formData.append('image', file);
    return await Api.post(`/microsoft/imageCaption`, {}, formData).then(({ data }) => {
        let caption = data?.captionResult?.text;
        let text = data?.readResult?.content;
        return `${caption} ${text ? ` with the text "${text}"` : ``}`;
    });
} 

export const getImageAICaptionURL = async (url) => {
    return await Api.post(`/microsoft/imageCaption`, {}, { url: url }).then(({ data }) => {
        let caption = data?.captionResult?.text;
        let text = data?.readResult?.content;
        return `${caption} ${text ? ` with the text "${text}"` : ``}`;
    });
} 