import OutOfWordsModal from "components/layout/OutOfWordsModal";
import SelectPlanModal from "components/layout/SelectPlanModal";
import { CompanyContext } from "contexts/CompanyContext";
import { PriorityModalContext } from "contexts/PriorityModalContext";
import { SessionContext } from "contexts/SessionContext";
import { get, replace } from "lodash";
import { useContext, useEffect, useState } from "react";
import Api from "services/Api";
import { replaceAll } from "utils/replaceAll";
import { formatSocialCommentObj, formatSocialConversationObj, formatSocialMessageObj, formatSocialPostObj } from "../../formatSocialPostObj";
import capitalize from "components/ui/utils/capitalize";
import { Notification, toast } from "components/ui";
import { getSocialNetworkIntegration } from "utils/getSocialNetworkIntegration";

export const useFacebook = () => {

    const { user } = useContext(SessionContext);
    const { company, companySocials } = useContext(CompanyContext);
    const _start_date = new Date(new Date().getTime() - 30 * 24 * 3600 * 1000);
    const _end_date = new Date();
    const network = "facebook";

    // const sendFacebookMessage = async (conversation, message) => {

    //     const socialNetwork = getSocialNetworkIntegration("facebook", companySocials);
    //     if (!socialNetwork) return [];

    //     const user_id = conversation?.user_id;
    //     const conversation_id = conversation?.id;

    //     let _theMessage;
    //     try {
    //         const response = await Api.post(`/socialNetworks/${socialNetwork.id}/proxy`, {}, {
    //             url: `https://graph.facebook.com/v16.0/${socialNetwork?.page_id}/messages`,
    //             method: "post",
    //             params: {
    //                 recipient: {'id': user_id},
    //                 messaging_type: "RESPONSE",
    //                 message: {'text': message}
    //             }
    //         }).then((response) => response);

    //         _theMessage = formatSocialMessageObj(
    //             "facebook",
    //             response?.message_id,
    //             new Date().getTime(),
    //             socialNetwork?.page_id,
    //             message
    //         );

    //         if (localStorage.getItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages`)) {
    //             let messages = JSON.parse(localStorage.getItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages`));
    //             messages.unshift(_theMessage);
    //             localStorage.setItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages`, JSON.stringify(messages));
    //         }
    //     } catch (err) {
    //         toast.push(<Notification type="danger" title={`An error ocurred while sending ${capitalize(socialNetwork.type)} message`} />);
    //     }

    //     // if (_theMessage) {
    //     //     if (localStorage.getItem(`${company.id}_${socialNetwork.id}_conversations`)) {
    //     //         let conversations = JSON.parse(localStorage.getItem(`${company.id}_${socialNetwork.id}_conversations`));
    //     //         conversations[conversations?.findIndex(e => e.id == conversation_id)]?.messages?.unshift(_theMessage);
    //     //         conversations[conversations?.findIndex(e => e.id == conversation_id)].message = _theMessage?.message;
    //     //         localStorage.setItem(`${company.id}_${socialNetwork.id}_conversations`, JSON.stringify(conversations));
    //     //     }
    //     // }

    //     return _theMessage;
    // }

    // const getFacebookConversationMessages = async (
    //     conversation_id,
    //     per_page = 100, //100
    // ) => {
    //     const socialNetwork = getSocialNetworkIntegration("facebook", companySocials);
    //     if (!socialNetwork) return [];
        
    //     if (localStorage.getItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages`) && localStorage.getItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages_timestamp`)) {
    //         const timestamp = localStorage.getItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages_timestamp`);
    //         if (parseInt(new Date().getTime()) <= parseInt(timestamp) + 1000 * 29) {
    //             return JSON.parse(localStorage.getItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages`));
    //         }
    //     }

    //     let messages = [];
    //     try {
    //         const response = await Api.post(`/socialNetworks/${socialNetwork.id}/proxy`, {}, {
    //             url: `https://graph.facebook.com/v16.0/${conversation_id}`,
    //             method: "get",
    //             params: {
    //                 fields: "messages{id,created_time,from,message,attachments}"
    //             }
    //         }).then((response) => response);
    //         messages = response?.messages?.data;
    //     } catch (err) {
    //         // toast.push(<Notification type="danger" title={`An error ocurred while fetching ${capitalize(socialNetwork.type)} conversations`} />)
    //     }
        
    //     if (messages) {
    //         messages = messages.map((item) => {
    //             return formatSocialMessageObj(
    //                 "facebook",
    //                 item?.id,
    //                 item?.created_time,
    //                 item?.from?.id,
    //                 item?.message,
    //                 item?.attachments?.data?.map(e => e?.image_data?.url ? e?.image_data?.url : e?.video_data?.url)
    //             );
    //         }); 
    //         if (messages && messages?.length) {
    //             Object.entries(localStorage).map(x => x[0]).filter(x => x.includes(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages`)).map(x => localStorage.removeItem(x));
    //             localStorage.setItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages`, JSON.stringify(messages));
    //             localStorage.setItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages_timestamp`, new Date().getTime());
    //         }
    //     } else {    
    //         if (localStorage.getItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages`)) {
    //             messages = JSON.parse(localStorage.getItem(`${company.id}_${socialNetwork.id}_${conversation_id}_conversation_messages`));
    //         }
    //     }

    //     return messages && messages?.length ? messages : [];
    // }

    const sendFacebookMessage = async (conversation, message) => {

        const socialNetwork = getSocialNetworkIntegration(network, companySocials);
        if (!socialNetwork) return [];

        const _message = await Api.post(`/messages`, {}, {
            conversation_id: conversation.id,
            message: message
        }).then(({ data: message }) => {
            return message;
        }).catch((err) => {
            toast.push(<Notification type="danger" title={err?.message || err} />);
        })

        return _message;
    }

    const deleteFacebookMessage = async (message_id) => {
        
        const socialNetwork = getSocialNetworkIntegration(network, companySocials);
        if (!socialNetwork) return [];

        try {
            const response = await Api.delete(`/messages/${message_id}`);
        } catch (e) {
            let message = e?.message;
            if (message) {
                message = message.split(`"error":{"message":"`);
                if (message?.length > 1) {
                    message = message[1];
                    message = message.split(`","type"`)?.[0];
                } else {
                    message = null;
                }
            }
            toast.push(<Notification type="danger" title={message || e?.message || `An error ocurred while deleteing the message`} />);
            return false;
        }
        
        return true;
    }

    const getFacebookConversationMessages = async (
        conversation_id,
        per_page = 100, 
    ) => {

        const socialNetwork = getSocialNetworkIntegration(network, companySocials);
        if (!socialNetwork) return [];

        const messages = await Api.get(`/messages?filter[conversation_id]=${conversation_id}&sort=-id&include=binaries`).then(
            ({ data: messages }) => messages);
        
        return messages;
    }

    const getFacebookConversations = async (filters) => {

        const socialNetwork = getSocialNetworkIntegration(network, companySocials);
        if (!socialNetwork) return [];

        let filterArray = [];
        filterArray.push(`filter[social_network_id]=${socialNetwork.id}`);
        if (!!filters?.query) {
            filterArray.push(`filter[externalUser.name]=${filters?.query}`);
        }
        if (!!filters?.assignedTo) {
            filterArray.push(`filter[user_id]=${filters?.assignedTo}`);
        }
        if (!!filters?.unread) {
            filterArray.push(`filter[has_unreadMessages]=${1}`);
        }

        try {
            const conversations = await Api.get(`/conversations?${filterArray.join("&")}&sort=lastMessage&include=lastMessage,unreadMessagesCount,externalUser.image`).then(({ data: conversations }) => {
                conversations = conversations.map(c => ({...c, network: network}));
                return conversations;
            });
            return conversations;
        } catch (err) {}
        
        return [];
    }

    const getFacebookPostsInsights = async (
        per_page = 100, // 100
        start_date = _start_date,
        end_date = _end_date
    ) => {
        const socialNetwork = getSocialNetworkIntegration("facebook", companySocials);
        if (!socialNetwork) return [];

        return getFacebookPosts(per_page, start_date, end_date, false);
    }
    

    const getFacebookPosts = async (
        per_page = 100, // 100
        start_date = _start_date,
        end_date = _end_date,
        get_comments = true 
    ) => {

        const socialNetwork = getSocialNetworkIntegration("facebook", companySocials);
        if (!socialNetwork) return [];

        // start_date = new Date(start_date).toISOString();
        // end_date = new Date(end_date).toISOString();
        start_date = new Date("2020-01-01").toISOString();
        end_date = new Date().toISOString();

        let posts = [];

        if (localStorage.getItem(`${socialNetwork.id}_posts_${start_date.split("T")[0]}_${end_date.split("T")[0]}`)) {
            const timestamp = localStorage.getItem(`${socialNetwork.id}_posts_timestamp_${start_date.split("T")[0]}_${end_date.split("T")[0]}`);
            if (parseInt(new Date().getTime()) <= parseInt(timestamp) + 1000 * 60 * 2) {
                posts = JSON.parse(localStorage.getItem(`${socialNetwork.id}_posts_${start_date.split("T")[0]}_${end_date.split("T")[0]}`));
            }
        }

        if (!posts?.length) {
            posts = await Api.get(`/socialNetworks/${socialNetwork.id}/posts?${start_date ? `&since=${Math.ceil(new Date(start_date).getTime() / 1000)}` : ``}${end_date ? `&until=${Math.ceil(new Date(end_date).getTime() / 1000)}` : ``}${per_page ? `&limit=${per_page}` : ``}&fields=attachments,created_time,from,full_picture,message,permalink_url,insights.metric(post_impressions,post_activity_by_action_type)`).then(({ data: posts }) => { 
                posts = posts?.map((post) => {
                    let media = [
                        ...post?.attachments?.data?.[0]?.subattachments?.data?.length > 0 ? 
                            post?.attachments?.data?.[0]?.subattachments?.data?.map(item => {
                                const type = item?.type == "photo" ? "image" : item?.type?.includes("video") ? "video" : item?.type;
                                return {
                                    type: type,
                                    url: item?.media?.source ? item?.media?.source : item?.media?.image?.src,
                                    width: item?.media?.image?.width,
                                    height: item?.media?.image?.height
                                }
                            }) : [{
                                type: post?.attachments?.data?.[0]?.type == "photo" || "profile_media" ? "image" : post?.attachments?.data?.[0]?.media?.type?.includes("video") ? "video" : (post?.attachments?.data?.[0]?.media?.type || post?.attachments?.data?.[0]?.type),
                                url: post?.attachments?.data?.[0]?.media?.source ? post?.attachments?.data?.[0]?.media?.source : post?.attachments?.data?.[0]?.media?.image?.src,
                                width: post?.attachments?.data?.[0]?.media?.image?.width,
                                height: post?.attachments?.data?.[0]?.media?.image?.height
                            }]
                    ].flat();

                    media = media?.filter(e => !!e.url);
    
                    if (!media?.[0]?.type && !post?.message) return undefined;
    
                    return formatSocialPostObj(
                        "facebook",
                        post?.id,
                        post?.from?.id,
                        post?.from?.name,
                        "",
                        "",
                        post?.created_time,
                        post?.message,
                        media,
                        post?.insights?.data?.filter(e => e?.name == "post_activity_by_action_type")?.[0]?.values?.[0]?.value?.like || 0, // likes
                        post?.insights?.data?.filter(e => e?.name == "post_activity_by_action_type")?.[0]?.values?.[0]?.value?.comment || 0, // comments
                        post?.insights?.data?.filter(e => e?.name == "post_impressions")?.[0]?.values?.[0]?.value || 0, // views
                        post?.insights?.data?.filter(e => e?.name == "post_activity_by_action_type")?.[0]?.values?.[0]?.value?.share || 0, // shares
                        undefined,
                        undefined,
                        undefined,
                        "FEED",
                        undefined,
                        [] // comments
                    )
                });
                posts = posts?.filter(e => e != undefined);
                localStorage.setItem(`${socialNetwork.id}_posts_timestamp_${start_date.split("T")[0]}_${end_date.split("T")[0]}`, new Date().getTime());
                return posts; 
            });
        }

        try {
            let comments = await Api.get(`/comments?countUnreadByPost=true`).then(({ data: comments }) => comments);
            posts = posts.map((post) => ({
                ...post,
                unreadComments_count: comments.find(e => e?.external_post_id == post?.id)?.comments_count
            }))
        } catch (err) {

        }

        if (posts) {
            localStorage.setItem(`${socialNetwork.id}_posts_${start_date.split("T")[0]}_${end_date.split("T")[0]}`, JSON.stringify(posts));
        }

        return posts;
    }

    const getFacebookComments = async (
        id
    ) => {

        const socialNetwork = getSocialNetworkIntegration("facebook", companySocials);
        if (!socialNetwork) return [];

        const comments = await Api.get(`/comments?sort=-id&filter[parent_id]=null&filter[external_post_id]=${id}&include=externalUser.image,children.externalUser.image`).then(({ data }) => data);

        return comments;
    }

    const replyToFacebookComment = async (
        comment,
        message,
    ) => {

        const socialNetwork = getSocialNetworkIntegration("facebook", companySocials);
        if (!socialNetwork) return [];

        await Api.post(`/comments`, {}, {
            external_comment_id: comment?.external_id,
            message: message
        });
    }
    
    // const replyToFacebookComment = async (
    //     comment,
    //     message,
    // ) => {

    //     const socialNetwork = getSocialNetworkIntegration("facebook", companySocials);
    //     if (!socialNetwork) return [];

    //     let start_date = new Date(_start_date).toISOString();
    //     let end_date = new Date(_end_date).toISOString();

    //     let _theComment;

    //     try {
    //         const response = await Api.post(`/socialNetworks/${socialNetwork.id}/proxy`, {}, {
    //             url: `https://graph.facebook.com/v16.0/${comment?.id}/comments`,
    //             method: "post",
    //             params: {
    //                 message: message
    //             }
    //         }).then((response) => response);
    //         _theComment = formatSocialCommentObj(
    //             "facebook",
    //             response?.id,
    //             new Date().toISOString(),
    //             socialNetwork?.page_id,
    //             socialNetwork?.name,
    //             socialNetwork?.username,
    //             socialNetwork?.image?.url,
    //             message,
    //             [],
    //             [],
    //         )
    //         if (localStorage.getItem(`${socialNetwork.id}_posts_${start_date.split("T")[0]}_${end_date.split("T")[0]}`)) {
    //             let posts = JSON.parse(localStorage.getItem(`${socialNetwork.id}_posts_${start_date.split("T")[0]}_${end_date.split("T")[0]}`));
    //             posts = posts?.map((post) => {
    //                 if (post?.comments && post?.comments?.length) {
    //                     post.comments = post?.comments?.map((_comment) => {
    //                         if (_comment?.id == comment?.id) {
    //                             if (_comment?.replies?.length == 0) {
    //                                 _comment.replies = [_theComment];
    //                             } else {
    //                                 _comment?.replies?.unshift(_theComment);
    //                             }
    //                         }
    //                         return _comment;
    //                     })
    //                 }
    //                 return post;
    //             });
    //             localStorage.setItem(`${socialNetwork.id}_posts_${start_date.split("T")[0]}_${end_date.split("T")[0]}`, JSON.stringify(posts));
    //         }
    //     } catch (err) {
    //         toast.push(<Notification type="danger" title={`An error ocurred while sending the ${capitalize(socialNetwork.type)} comment`} />);
    //     }

    //     return _theComment;
    // }

    return { 
        getFacebookPosts, 
        getFacebookPostsInsights, 
        getFacebookConversations, 
        getFacebookConversationMessages, 
        sendFacebookMessage, 
        getFacebookComments, 
        replyToFacebookComment,
        deleteFacebookMessage 
    }
}

