import React, { ReactNode, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Api from "services/Api";
import { getAspectRatio } from "utils/getAspectRatio";
import { CompanyContext } from "./CompanyContext";
import MediaFilters from "components/layout/MediaFilters";
import { SessionContext } from "./SessionContext";

const MediaContext = React.createContext({
    myMedia: [],
    updateMyMedia: () => void 0,
    deleteAssetMyMedia: (id) => void 0,
    isLoadingMyMedia: false,
    filtersMyMedia: [],
    setFiltersMyMedia: ({}) => void 0,
    filtersMyMediaDrawerOpened: false,
    setFiltersMyMediaDrawerOpened: (_openBool) => void 0,
    loadMoreMyMedia: () => void 0,
    hasMoreMyMedia: false
});

const MediaProvider = ({ children }) => {
    
    const [ myMedia, setMyMedia ] = useState([]);
    const { user } = useContext(SessionContext);
    const { company } = useContext(CompanyContext);
    const [ isLoadingMyMedia, setIsLoadingMyMedia ] = useState(false);
    const [ filtersMyMedia, setFiltersMyMedia ] = useState({});
    const [ filtersMyMediaDrawerOpened, setFiltersMyMediaDrawerOpened ] = useState(false);
    const [ loadMoreURL, setLoadMoreURL ] = useState(false);
    const isInitialMount = useRef(true);

    const updateMyMedia = (loadMore = false) => {
        if (!company) return;
        try {
            setIsLoadingMyMedia(true);
            Api.get(loadMore && loadMoreURL ? loadMoreURL : `/companies/${company?.id}/assets?sort=-created_at&include=posts,image,image.thumbnail,video,video.thumbnail,user,children.user,children.user.image,user.image,children.image,children.video${filtersMyMedia?.media_types && filtersMyMedia?.media_types?.length > 0 ? `&filter[type]=${filtersMyMedia?.media_types?.map(e => e.value)?.join(",")}` : `&filter[type]=image,video`}${filtersMyMedia?.query ? `&filter[text]=${filtersMyMedia?.query}` : ``}${filtersMyMedia?.create_date_range && filtersMyMedia?.create_date_range?.length > 0 ? `&filter[created_at_after]=${new Date(filtersMyMedia?.create_date_range[0]).toDateString()}&filter[created_at_before]=${new Date(filtersMyMedia?.create_date_range[1]).toDateString()}` : ``}${filtersMyMedia?.users && filtersMyMedia?.users?.length > 0 ? `&filter[user_id]=${filtersMyMedia?.users?.map(e => e.value)?.join(",")}` : ``}${filtersMyMedia?.social_networks && filtersMyMedia?.social_networks?.length > 0 ? `&filter[social_network_type_with_children]=${filtersMyMedia?.social_networks?.map(e => e.value)?.join(",")}` : ``}${filtersMyMedia?.unused_assets ? `&filter[has_relation]=!posts` : ``}`, {}).then(({ data: media, links, meta }) => {
                media = media.map((item) => { 
                    return {
                        ...item, 
                        src: item?.image 
                            ? item?.image?.thumbnail?.url ? item?.image?.thumbnail?.url : item?.image?.url 
                            : item?.video?.url,
                        original: item?.image 
                            ? item?.image?.url 
                            : item?.video?.url
                    }
                });
                media = media.map((item) => { 
                    var asset = item?.image ? item?.image : item?.video; 
                    return {
                        ...item, 
                        aspectRatio: getAspectRatio(asset?.width, asset?.height) 
                    }
                });
                setLoadMoreURL(links?.next);
                loadMore && loadMoreURL ? setMyMedia([...myMedia, ...media]) : setMyMedia(media);

                window?.Intercom('update', {
                    "media_quantity": meta?.total ? meta?.total : 0
                });
            }).finally(() => {
                setIsLoadingMyMedia(false);
            });
        } catch (err) {
            setIsLoadingMyMedia(false);
        }
    }

    const loadMoreMyMedia = () => {
        if (!loadMoreURL) return;
        updateMyMedia(true);
    }

    const deleteAssetMyMedia = async (id) => {
        return Api.delete(`/assets/${id}?include_children=1`, {}, {}).then(() => {
            return updateMyMedia();
        });
    }

    useEffect(() => {
        if (Object.keys(filtersMyMedia)?.length != Object.entries(filtersMyMedia).filter(([_, v]) => !!v)?.length) {
            setFiltersMyMedia(Object.fromEntries(Object.entries(filtersMyMedia).filter(([_, v]) => !!v)));
        }
        localStorage.setItem(`${user?.id}_filtersMyMedia`, JSON.stringify(filtersMyMedia));
    }, [filtersMyMedia]);

    useEffect(() => {
        if (!!user) {
            if (localStorage.getItem(`${user?.id}_filtersMyMedia`)) {
                try {
                    const _filters = JSON.parse(localStorage.getItem(`${user?.id}_filtersMyMedia`));
                    if (!!_filters) {
                        setFiltersMyMedia(_filters);
                    }
                } catch (err) {}
            }
        }
    }, [user?.id]);

    useEffect(() => {
        if (!company) setMyMedia([]);
    }, [company]);

    useEffect(() => {
        if (isInitialMount.current) {
           isInitialMount.current = false;
        } else {
            updateMyMedia();
        }
    }, [filtersMyMedia, company?.id]);

    return (
        <MediaContext.Provider value={{ 
            myMedia: myMedia,
            updateMyMedia: updateMyMedia,
            deleteAssetMyMedia: deleteAssetMyMedia,
            isLoadingMyMedia: isLoadingMyMedia,
            filtersMyMedia: filtersMyMedia,
            setFiltersMyMedia: setFiltersMyMedia,
            filtersMyMediaDrawerOpened: filtersMyMediaDrawerOpened,
            setFiltersMyMediaDrawerOpened: setFiltersMyMediaDrawerOpened,
            loadMoreMyMedia: loadMoreMyMedia,
            hasMoreMyMedia: loadMoreURL != "" && loadMoreURL != undefined
        }}>
            {children}
            {filtersMyMediaDrawerOpened && <MediaFilters />}
        </MediaContext.Provider>
    );
};

export { MediaContext, MediaProvider };